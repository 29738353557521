import { createApp } from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/index.css";
import { register as registerServiceWorker } from "@/registerServiceWorker";

//Base
const baseComponentFiles = import.meta.glob("./components/base/*.vue", {
  eager: true,
});

const app = createApp(App);

const componentFiles = [...Object.entries(baseComponentFiles)];

componentFiles.forEach(([path, m]) => {
  const componentName = upperFirst(
    camelCase(
      path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  app.component(componentName, m.default);
});

const setup = async () => {
  // Register service worker - Allow testing in dev mode as well with a console info message
  const enableDevPWA = false; // Set to false to prevent service worker registration in development

  if (process.env.NODE_ENV === "production" || enableDevPWA) {
    registerServiceWorker();
    if (process.env.NODE_ENV !== "production") {
      console.info("Service Worker registered in development mode for testing");
    }
  } else {
    console.info("Service Worker not registered in development mode");
  }

  // Note: Analytics API fetch interception removed as it's not needed

  app.use(store).use(router).mount("#app");
};

setup();
