import { fetchPosts, fetchCategories, fetchPostById } from "./wpService";

// Custom WASM blog post data structured to match WordPress API format
const wasmBlogPost = {
  id: 9999,
  date: "2025-03-27T10:30:00.000Z",
  slug: "why-webassembly-is-changing-how-we-build-for-the-web",
  type: "post",
  link: "/blog/post/9999",
  title: {
    rendered: "Building Blocks for the Future Web",
  },
  excerpt: {
    rendered:
      "This isn't just another blog post—it's a live, offline-first, WebAssembly-powered experience that demonstrates the future of performance and resilience on the web.",
  },
  content: {
    rendered: "",
  },
  featured_media: 1001,
  jetpack_featured_media_url: "/blog-assets/wasm-architecture.svg",
  categories: [1],
  tags: [],
  isWasmPost: true,
  acf: {
    attributable_author_name: "WebAssembly Team",
    attributable_author_job_title: "Woven Engineering",
  },
  _embedded: {
    "wp:term": [
      [
        {
          id: 1,
          name: "Software Development",
          slug: "software-development",
        },
      ],
    ],
  },
};

// Enhanced fetchPosts that injects our custom post
const enhancedFetchPosts = async (
  numberOfPosts = 3,
  page = 1,
  category = null
) => {
  // Check if we should include our WASM post
  const shouldIncludeWasmPost = page === 1 && (!category || category === 1359);

  // If we're going to add our WASM post, request one fewer post from WordPress
  // so the total number remains what was requested
  const adjustedNumberOfPosts = shouldIncludeWasmPost
    ? Math.max(1, numberOfPosts - 1)
    : numberOfPosts;

  // Get regular WordPress posts
  const response = await fetchPosts(adjustedNumberOfPosts, page, category);

  // Add our custom post if appropriate
  if (shouldIncludeWasmPost) {
    // Add our custom post to the array and sort by date
    const combinedPosts = [...response.data, wasmBlogPost];

    // Sort by date in descending order (newest first)
    combinedPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

    response.data = combinedPosts;
  }

  return response;
};

// Enhanced fetchPostById that returns our custom post for its special ID
const enhancedFetchPostById = async (postId) => {
  // Handle both string "wasm-blog" and numeric 9999 IDs for our custom post
  if (
    postId === "wasm-blog" ||
    postId === "9999" ||
    parseInt(postId) === 9999
  ) {
    return wasmBlogPost;
  }

  // Otherwise, fetch from WordPress as usual
  return fetchPostById(postId);
};

export {
  enhancedFetchPosts as fetchPosts,
  enhancedFetchPostById as fetchPostById,
  fetchCategories,
  wasmBlogPost,
};
