/**
 * Service Worker Registration Module
 *
 * Registers a service worker and handles lifecycle events.
 */

// Check if service workers are supported
const isServiceWorkerSupported = "serviceWorker" in navigator;

/**
 * Register the service worker
 * @returns {Promise<ServiceWorkerRegistration|null>}
 */
export async function register() {
  if (!isServiceWorkerSupported) {
    console.warn("Service workers are not supported in this browser");
    return null;
  }

  try {
    // Register the service worker
    // Always use the root path for the service worker
    const registration = await navigator.serviceWorker.register(
      "/service-worker.js",
      {
        scope: "/",
      }
    );

    console.log("Service worker registered successfully:", registration.scope);

    // Handle updates
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;

      if (!installingWorker) return;

      installingWorker.onstatechange = () => {
        if (installingWorker.state === "installed") {
          if (navigator.serviceWorker.controller) {
            // New content is available, show a notification
            console.log("New content is available, please refresh");

            // Dispatch an event for the app to show a notification
            window.dispatchEvent(
              new CustomEvent("swUpdate", { detail: registration })
            );
          } else {
            // Content is cached for offline use
            console.log("Content is cached for offline use");
          }
        }
      };
    };

    return registration;
  } catch (error) {
    console.error("Error registering service worker:", error);
    return null;
  }
}

/**
 * Unregister all service workers
 * @returns {Promise<boolean>}
 */
export async function unregister() {
  if (!isServiceWorkerSupported) return false;

  try {
    const registration = await navigator.serviceWorker.ready;
    await registration.unregister();
    return true;
  } catch (error) {
    console.error("Error unregistering service worker:", error);
    return false;
  }
}

/**
 * Send message to service worker
 * @param {Object} message - Message to send to the service worker
 * @returns {Promise<void>}
 */
export async function sendMessage(message) {
  if (!isServiceWorkerSupported) return;

  const registration = await navigator.serviceWorker.ready;
  if (registration.active) {
    registration.active.postMessage(message);
  }
}

/**
 * Force refresh the service worker
 * @returns {Promise<void>}
 */
export async function forceRefresh() {
  if (!isServiceWorkerSupported) return;

  const registration = await navigator.serviceWorker.ready;
  if (registration.waiting) {
    // Send a message to the waiting service worker to skip waiting and activate
    sendMessage({ type: "SKIP_WAITING" });
  }
}

/**
 * Listen for messages from the service worker
 * @param {Function} callback - Callback to handle messages
 * @returns {void}
 */
export function listenForMessages(callback) {
  if (!isServiceWorkerSupported) return;

  navigator.serviceWorker.addEventListener("message", (event) => {
    callback(event.data);
  });
}

// Register the service worker automatically if in production
if (process.env.NODE_ENV === "production") {
  register();
}
