<template>
  <div class="font-light">
    <div v-if="!loadingPosts && posts.length >= 2" class="px-6 lg:px-10">
      <section class="max-w-7xl mx-auto">
        <h2 class="mt-6 text-4xl lg:text-5xl font-bold text-white">
          Latest Insights
        </h2>
        <p class="mt-4 text-base lg:text-lg text-slate-300">
          Discover the latest insights, tips, and trends in our industry. Dive
          into our world of innovative ideas and learn more about what drives
          us.
        </p>
        <div
          class="mt-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-8"
        >
          <BlogPostCard v-for="post in posts" :key="post.id" :post="post" />
        </div>
        <div class="flex justify-center mt-8">
          <router-link
            to="/blog"
            class="inline-flex items-center justify-center rounded-lg border py-[calc(theme(spacing.3)-1px)] px-[calc(theme(spacing.4)-1px)] border-woven-green text-woven-green"
          >
            <span class="mr-2.5">More Insights</span>
            <ArrowRightIcon class="h-4 w-4" />
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import { ref, onMounted, watch } from "vue";
import BlogPostCard from "@/components/BlogPostCard.vue";
import { fetchPosts } from "@/shared/services/enhancedWpService";

export default {
  name: "InsightsSection",
  components: {
    ArrowRightIcon,
    BlogPostCard,
  },
  props: {
    threadName: {
      type: String,
      required: true,
    },
    categoryId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const loadingPosts = ref(true);
    const posts = ref([]);

    const loadPosts = async () => {
      try {
        loadingPosts.value = true;

        // Handle special case for homepage (all threads)
        if (props.threadName === "all" && props.categoryId === 0) {
          // Fetch posts without category filter
          const { data } = await fetchPosts(3, 1);
          posts.value = data;
        } else {
          // Fetch posts for the provided category ID
          const { data } = await fetchPosts(
            3,
            1,
            props.categoryId || undefined
          );
          posts.value = data;
        }
      } catch (error) {
        console.error("Failed to load posts:", error);
      } finally {
        loadingPosts.value = false;
      }
    };

    // Load posts initially
    onMounted(loadPosts);

    // Reload posts if categoryId changes
    watch(() => props.categoryId, loadPosts);

    return {
      loadingPosts,
      posts,
    };
  },
};
</script>

<style lang="scss"></style>
