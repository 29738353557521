<template>
  <div class="flex justify-start mt-4 mb-10">
    <div class="relative inline-block text-left min-w-[200px] w-full max-w-xs">
      <div>
        <button
          id="menu-button"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-slate-600 shadow-sm px-4 py-3 bg-slate-800 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-woven-green transition-colors duration-200"
          :class="{ 'opacity-80': isLoading }"
          aria-expanded="true"
          aria-haspopup="true"
          :disabled="isLoading"
          @click="isOpen = !isOpen"
        >
          <svg
            v-if="!isLoading"
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mr-2 text-woven-green"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
          <div
            v-else
            class="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-woven-green mr-2"
          ></div>
          <span class="whitespace-nowrap overflow-hidden text-ellipsis">
            {{
              selectedCategory ? selectedCategory.name : "Filter By Category"
            }}
          </span>
          <svg
            class="-mr-1 ml-2 h-5 w-5 transform transition-transform duration-200"
            :class="{ 'rotate-180': isOpen }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          v-if="isOpen"
          class="origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg bg-slate-800 ring-1 ring-slate-600 ring-opacity-5 focus:outline-none z-10"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <button
              class="flex items-center text-slate-300 w-full px-4 py-3 text-sm text-left hover:bg-slate-700 transition-colors duration-150"
              :class="{
                'bg-slate-700 text-woven-green font-medium':
                  selectedCategory === null,
              }"
              role="menuitem"
              tabindex="-1"
              @click="clearCategory"
            >
              <svg
                v-if="selectedCategory === null"
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <span v-else class="w-4 mr-2"></span>
              All Categories
            </button>

            <button
              v-for="category in categories"
              :key="category.id"
              class="flex items-center text-slate-300 w-full px-4 py-3 text-sm text-left hover:bg-slate-700 transition-colors duration-150"
              :class="{
                'bg-slate-700 text-woven-green font-medium':
                  selectedCategory && category.id === selectedCategory.id,
              }"
              role="menuitem"
              tabindex="-1"
              @click="selectCategory(category)"
            >
              <svg
                v-if="selectedCategory && category.id === selectedCategory.id"
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <span v-else class="w-4 mr-2"></span>
              {{ category.name }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted } from "vue";

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      default: null,
    },
  },
  emits: ["category-selected", "update:modelValue"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const selectedCategory = ref(null);
    const isLoading = ref(false);

    // Watch for changes in the modelValue prop to update the internal selectedCategory
    watch(
      () => props.modelValue,
      (newValue) => {
        selectedCategory.value = newValue;
      },
      { immediate: true }
    );

    const selectCategory = (category) => {
      selectedCategory.value = category;
      isLoading.value = true;
      emit("category-selected", category);
      emit("update:modelValue", category);
      isOpen.value = false;
    };

    const clearCategory = () => {
      if (selectedCategory.value !== null) {
        selectedCategory.value = null;
        isLoading.value = true;
        emit("category-selected", null);
        emit("update:modelValue", null);
      }
      isOpen.value = false;
    };

    // Watch for external loading state changes
    watch(
      () => props.loading,
      (newValue) => {
        isLoading.value = newValue;
      }
    );

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (isOpen.value && !event.target.closest(".relative")) {
        isOpen.value = false;
      }
    };

    // Add event listener when component is mounted
    onMounted(() => {
      window.addEventListener("click", handleClickOutside);
    });

    // Clean up event listener when component is unmounted
    onUnmounted(() => {
      window.removeEventListener("click", handleClickOutside);
    });

    return {
      isOpen,
      selectedCategory,
      selectCategory,
      clearCategory,
      isLoading,
    };
  },
};
</script>

<style scoped>
/* Ensure text truncation works properly */
.whitespace-nowrap {
  max-width: calc(100% - 3.5rem);
}
</style>
