<template>
  <!-- Loading overlay spinner - appears on top of content instead of replacing it -->
  <div
    v-if="loading && !initialLoad"
    class="fixed inset-0 bg-slate-900/70 flex justify-center items-center z-20"
  >
    <div
      class="bg-slate-800 rounded-lg p-8 shadow-lg flex flex-col items-center"
    >
      <div
        class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-woven-green mb-4"
      ></div>
      <p class="text-slate-300 text-lg">Loading post...</p>
    </div>
  </div>

  <!-- Initial loading state - only shown on first page load -->
  <div v-if="initialLoad" class="flex justify-center items-center h-screen">
    <div
      class="bg-slate-800 rounded-lg p-8 shadow-lg flex flex-col items-center"
    >
      <div
        class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-woven-green mb-4"
      ></div>
      <p class="text-slate-300 text-lg">Loading post...</p>
    </div>
  </div>

  <div
    v-else-if="error"
    class="flex justify-center items-center h-screen text-red-500"
  >
    Error: {{ error.message }}
  </div>

  <div v-else class="blog-post-view-container">
    <WasmBlog v-if="post && post.isWasmPost" :post="post" />
    <BlogPost v-else :post="post" />
  </div>
</template>

<script>
import BlogPost from "@/components/BlogPost.vue";
import WasmBlog from "@/components/threads/wasm-blog.vue";
import { fetchPostById } from "@/shared/services/enhancedWpService";

export default {
  components: {
    BlogPost,
    WasmBlog,
  },
  beforeRouteUpdate(to, from, next) {
    // If the route changes but we're still in the same component
    // (e.g., navigating between different blog posts)
    if (to.name === from.name && to.params.id !== from.params.id) {
      this.loading = true;
      this.initialLoad = false; // Not initial load, show overlay instead
      this.error = null;
      this.post = null;

      // Wait for the navigation to complete before loading the new post
      next();
      this.loadPost();
    } else {
      next();
    }
  },
  props: {
    // Add an id prop to properly handle the attribute
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      post: null,
      loading: true,
      initialLoad: true,
      error: null,
    };
  },
  async created() {
    await this.loadPost();
  },
  methods: {
    async loadPost() {
      const postId = this.$route.params.id || this.id;
      try {
        this.post = await fetchPostById(postId);
        this.loading = false;
        this.initialLoad = false;
      } catch (error) {
        this.error = error;
        this.loading = false;
        this.initialLoad = false;
      }
    },
  },
};
</script>

<style scoped>
.blog-post-view-container {
  display: contents;
}
</style>
