// offlineSync.js
// This module provides utilities for offline storage and data synchronization

// API endpoint for syncing data
const API_ENDPOINT = "https://woven-reactions-api.kbehr.workers.dev/api/sync";

// Explicitly export the API_ENDPOINT
export { API_ENDPOINT };

// Check if we're running in a browser environment
const isBrowser = typeof window !== "undefined";

// IndexedDB database name
const DB_NAME = "woven_blog_offline_db";
const DB_VERSION = 1;

// Cache for storage instance
let storageInstance = null;
let syncIntervalIds = []; // Track any intervals created by the offline sync system

/**
 * Cleans up any resources used by the offline sync system
 */
export function cleanupOfflineSync() {
  console.log("Cleaning up offline sync resources");

  // Clear any intervals that were registered
  if (syncIntervalIds && syncIntervalIds.length > 0) {
    console.log(`Clearing ${syncIntervalIds.length} offline sync intervals`);
    syncIntervalIds.forEach((intervalId) => {
      clearInterval(intervalId);
    });
    syncIntervalIds = [];
  }

  // Close the database connection if open
  if (storageInstance && storageInstance.close) {
    try {
      storageInstance.close();
    } catch (e) {
      console.warn("Error closing IndexedDB connection:", e);
    }
  }

  // Reset storage instance
  storageInstance = null;

  console.log("Offline sync cleanup complete");
  return true;
}

/**
 * Register an interval with the offline sync system for centralized cleanup
 * @param {number} intervalId - The interval ID to track
 */
export function registerSyncInterval(intervalId) {
  if (!syncIntervalIds) {
    syncIntervalIds = [];
  }

  if (intervalId && !syncIntervalIds.includes(intervalId)) {
    syncIntervalIds.push(intervalId);
    console.log(
      `Registered interval ${intervalId} with offline sync system. Total: ${syncIntervalIds.length}`
    );
  }
}

/**
 * Returns the current online status
 * @returns {boolean} True if online, false otherwise
 */
export function isOnline() {
  return isBrowser ? navigator.onLine : false;
}

/**
 * Get the offline storage mechanism
 * Uses IndexedDB if available, falls back to localStorage
 * @returns {Promise<Object>} Storage interface
 */
export async function getOfflineStorage() {
  // Return cached instance if available
  if (storageInstance) {
    return storageInstance;
  }

  // Create storage instance based on available APIs
  if (isBrowser && "indexedDB" in window) {
    try {
      const indexedDbStorage = await createIndexedDbStorage();
      storageInstance = indexedDbStorage;
      return indexedDbStorage;
    } catch (err) {
      console.warn(
        "IndexedDB not available, falling back to localStorage:",
        err
      );
      // Fall through to localStorage
    }
  }

  // Fallback to localStorage
  if (isBrowser && "localStorage" in window) {
    const localStorageAdapter = createLocalStorageAdapter();
    storageInstance = localStorageAdapter;
    return localStorageAdapter;
  }

  // Memory-only storage for non-browser environments or when no storage is available
  console.warn("No persistent storage available, using memory storage");
  const memoryStorage = createMemoryStorage();
  storageInstance = memoryStorage;
  return memoryStorage;
}

/**
 * Create a storage interface using IndexedDB
 * @returns {Promise<Object>} Storage interface
 */
async function createIndexedDbStorage() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      reject(new Error("Failed to open IndexedDB: " + event.target.error));
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      // Create object stores if they don't exist
      if (!db.objectStoreNames.contains("keyValueStore")) {
        db.createObjectStore("keyValueStore");
      }
    };

    request.onsuccess = (event) => {
      const db = event.target.result;

      // Create storage interface
      const storage = {
        /**
         * Get a value from storage
         * @param {string} key - The key to retrieve
         * @returns {Promise<*>} The stored value
         */
        getItem: (key) => {
          return new Promise((resolve, reject) => {
            const transaction = db.transaction(["keyValueStore"], "readonly");
            const store = transaction.objectStore("keyValueStore");
            const request = store.get(key);

            request.onerror = () => {
              reject(new Error("Failed to get item: " + request.error));
            };

            request.onsuccess = () => {
              resolve(request.result);
            };
          });
        },

        /**
         * Store a value
         * @param {string} key - The key to store under
         * @param {*} value - The value to store
         * @returns {Promise<void>}
         */
        setItem: (key, value) => {
          return new Promise((resolve, reject) => {
            const transaction = db.transaction(["keyValueStore"], "readwrite");
            const store = transaction.objectStore("keyValueStore");
            const request = store.put(value, key);

            request.onerror = () => {
              reject(new Error("Failed to set item: " + request.error));
            };

            request.onsuccess = () => {
              resolve();
            };
          });
        },

        /**
         * Remove a value
         * @param {string} key - The key to remove
         * @returns {Promise<void>}
         */
        removeItem: (key) => {
          return new Promise((resolve, reject) => {
            const transaction = db.transaction(["keyValueStore"], "readwrite");
            const store = transaction.objectStore("keyValueStore");
            const request = store.delete(key);

            request.onerror = () => {
              reject(new Error("Failed to remove item: " + request.error));
            };

            request.onsuccess = () => {
              resolve();
            };
          });
        },

        /**
         * Clear all stored values
         * @returns {Promise<void>}
         */
        clear: () => {
          return new Promise((resolve, reject) => {
            const transaction = db.transaction(["keyValueStore"], "readwrite");
            const store = transaction.objectStore("keyValueStore");
            const request = store.clear();

            request.onerror = () => {
              reject(new Error("Failed to clear storage: " + request.error));
            };

            request.onsuccess = () => {
              resolve();
            };
          });
        },
      };

      resolve(storage);
    };
  });
}

/**
 * Create a storage interface using localStorage
 * @returns {Object} Storage interface
 */
function createLocalStorageAdapter() {
  return {
    /**
     * Get a value from storage
     * @param {string} key - The key to retrieve
     * @returns {Promise<*>} The stored value
     */
    getItem: async (key) => {
      const value = localStorage.getItem(key);
      return value;
    },

    /**
     * Store a value
     * @param {string} key - The key to store under
     * @param {*} value - The value to store
     * @returns {Promise<void>}
     */
    setItem: async (key, value) => {
      localStorage.setItem(key, value);
    },

    /**
     * Remove a value
     * @param {string} key - The key to remove
     * @returns {Promise<void>}
     */
    removeItem: async (key) => {
      localStorage.removeItem(key);
    },

    /**
     * Clear all stored values
     * @returns {Promise<void>}
     */
    clear: async () => {
      localStorage.clear();
    },
  };
}

/**
 * Create an in-memory storage (for fallback)
 * @returns {Object} Storage interface
 */
function createMemoryStorage() {
  const store = {};

  return {
    /**
     * Get a value from storage
     * @param {string} key - The key to retrieve
     * @returns {Promise<*>} The stored value
     */
    getItem: async (key) => {
      return store[key];
    },

    /**
     * Store a value
     * @param {string} key - The key to store under
     * @param {*} value - The value to store
     * @returns {Promise<void>}
     */
    setItem: async (key, value) => {
      store[key] = value;
    },

    /**
     * Remove a value
     * @param {string} key - The key to remove
     * @returns {Promise<void>}
     */
    removeItem: async (key) => {
      delete store[key];
    },

    /**
     * Clear all stored values
     * @returns {Promise<void>}
     */
    clear: async () => {
      Object.keys(store).forEach((key) => {
        delete store[key];
      });
    },
  };
}

/**
 * Get a session ID for the current user
 * For multi-device syncing, we now support a userId parameter in the URL
 * @returns {string} Session ID string
 */
export function getSessionId() {
  if (!isBrowser) return "server";

  const SESSION_KEY = "woven_session_id";
  const USER_KEY = "woven_user_id";

  // First check if userId is specified in the URL for testing cross-device
  if (isBrowser && window.location.search) {
    const urlParams = new URLSearchParams(window.location.search);
    const urlUserId = urlParams.get("userId");

    if (urlUserId) {
      // Store this user ID in localStorage
      try {
        localStorage.setItem(USER_KEY, urlUserId);
        console.log(`Using URL-provided user ID: ${urlUserId}`);
        return `user_${urlUserId}`;
      } catch (e) {
        console.warn("Could not save user ID to localStorage:", e);
      }
    }
  }

  // Second, check for a persistent user ID in localStorage
  const existingUserId = localStorage.getItem(USER_KEY);
  if (existingUserId) {
    return `user_${existingUserId}`;
  }

  // Fall back to device-specific session ID behavior
  // First try to get the ID from localStorage for persistence across refreshes
  const existingLocalId = localStorage.getItem(SESSION_KEY);
  if (existingLocalId) return existingLocalId;

  // If not in localStorage, generate a new session ID
  const newId =
    "session_" + Date.now() + "_" + Math.random().toString(36).substring(2, 10);

  // Save it to localStorage for persistence
  try {
    localStorage.setItem(SESSION_KEY, newId);
  } catch (e) {
    console.warn("Could not save session ID to localStorage:", e);
  }

  return newId;
}

/**
 * Sync data with the server
 * @param {Array} pendingData - Array of pending data to sync
 * @returns {Promise<Object>} - Result of the sync operation
 */
export async function syncWithServer(pendingData) {
  if (!isOnline()) {
    return { success: false, reason: "offline" };
  }

  // Get the current session ID for logging
  const currentSessionId = getSessionId();
  console.log(`Syncing with server using session ID: ${currentSessionId}`);

  try {
    // Create a timeout promise that rejects after 5 seconds
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error("Request timeout")), 5000);
    });

    // Ensure each item has all required fields
    const enhancedPendingData = pendingData.map((item) => {
      // Convert 'reaction' to 'reactionData' if needed
      if (item.reaction && !item.reactionData) {
        item.reactionData = item.reaction;
        delete item.reaction; // Remove the old property
      }

      // Make sure reactionData has all required fields
      if (item.reactionData && !item.reactionData.id) {
        item.reactionData.id = `reaction_${Date.now()}_${Math.random()
          .toString(36)
          .substring(2, 10)}`;
      }

      if (item.reactionData && !item.reactionData.timestamp) {
        item.reactionData.timestamp = Date.now();
      }

      // Ensure we have the action field (add/remove)
      if (!item.action) {
        item.action = "add";
      }

      // Ensure we have the timestamp field
      if (!item.timestamp) {
        item.timestamp = Date.now();
      }

      // Make sure synced flag is set
      if (item.synced === undefined) {
        item.synced = false;
      }

      return item;
    });

    console.log(`Sending ${enhancedPendingData.length} reactions to server`);

    // Show the first few reactions for debugging
    if (enhancedPendingData.length > 0) {
      console.log(`Sample reaction: ${JSON.stringify(enhancedPendingData[0])}`);
    }

    // The actual fetch request
    const fetchPromise = fetch(API_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pendingReactions: enhancedPendingData,
        sessionId: currentSessionId,
        device: isBrowser ? navigator.userAgent : "unknown",
        timestamp: Date.now(),
        // Include URL param userId if present for debugging
        userId:
          isBrowser &&
          new URLSearchParams(window.location.search).get("userId"),
      }),
    });

    // Race the fetch against the timeout
    const response = await Promise.race([fetchPromise, timeoutPromise]);

    if (!response.ok) {
      throw new Error(
        `Server responded with ${response.status}: ${await response.text()}`
      );
    }

    const result = await response.json();
    console.log(`Sync result: ${JSON.stringify(result)}`);

    return {
      success: result.success,
      syncedCount: result.syncedCount || 0,
      results: result.results || [],
    };
  } catch (error) {
    console.error("Sync failed:", error);
    return {
      success: false,
      reason: error.message === "Request timeout" ? "timeout" : error.message,
    };
  }
}

/**
 * Send a session heartbeat to the server
 * @param {Object} [additionalData] - Additional data to include with the heartbeat
 * @returns {Promise<boolean>} - Whether the heartbeat was successful
 */
export async function sendHeartbeat(additionalData = {}) {
  if (!isOnline()) {
    return false;
  }

  try {
    const response = await fetch(
      `${API_ENDPOINT.replace("/sync", "/sessions/heartbeat")}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sessionId: getSessionId(),
          timestamp: Date.now(),
          url: isBrowser ? window.location.href : undefined,
          userAgent: isBrowser ? navigator.userAgent : undefined,
          ...additionalData,
        }),
      }
    );

    return response.ok;
  } catch (error) {
    console.warn("Failed to send heartbeat:", error);
    return false;
  }
}

/**
 * Get active sessions from the server
 * @returns {Promise<Array>} - Array of active sessions
 */
export async function getActiveSessions() {
  if (!isOnline()) {
    return [];
  }

  try {
    const response = await fetch(
      `${API_ENDPOINT.replace("/sync", "/sessions/active")}`
    );

    if (!response.ok) {
      throw new Error(`Server responded with ${response.status}`);
    }

    const data = await response.json();
    return data.success ? data.sessions : [];
  } catch (error) {
    console.warn("Failed to get active sessions:", error);
    return [];
  }
}
