<!-- SectionReactions Component -->
<template>
  <div class="section-reactions">
    <!-- Reaction Summary -->
    <div
      v-if="hasReactions"
      :key="JSON.stringify(visibleReactionTypes)"
      class="reaction-summary flex items-center mb-2"
    >
      <div class="text-xs text-slate-400 mr-2">Readers' reactions:</div>
      <div class="flex space-x-1">
        <div
          v-for="reactionType in visibleReactionTypes"
          :key="reactionType.type"
          class="reaction-badge flex items-center bg-slate-800 px-2 py-1 rounded-full text-xs"
        >
          <span class="mr-1">{{ reactionType.emoji }}</span>
          <span>{{ reactionType.count }}</span>
        </div>
      </div>
    </div>

    <!-- Reaction Buttons - Always visible now -->
    <div class="add-reaction mb-3">
      <div class="text-xs text-slate-400 mb-2">Add your reaction:</div>

      <div class="reaction-buttons flex space-x-2 overflow-x-auto pb-1">
        <button
          v-for="reaction in reactionOptions"
          :key="reaction.id"
          class="reaction-button px-3 py-1 rounded-full text-sm border border-slate-700 hover:bg-slate-700 transition-colors"
          :class="{
            'bg-slate-700': localUserReactions.includes(reaction.id),
            relative: pendingReactions.includes(reaction.id),
          }"
          @click="toggleReaction(reaction.id)"
        >
          <span class="mr-1">{{ reaction.emoji }}</span>
          <span>{{ reaction.label }}</span>
          <!-- Pending indicator dot -->
          <span
            v-if="pendingReactions.includes(reaction.id)"
            class="absolute -top-1 -right-1 w-2 h-2 bg-amber-400 rounded-full"
            title="This reaction is pending sync"
          ></span>
        </button>
      </div>
    </div>

    <!-- Offline/sync status -->
    <div
      v-if="!isOnline || hasPendingReactions"
      class="text-xs mt-1"
      :class="isOnline ? 'text-slate-400' : 'text-amber-400'"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-3 w-3 mr-1 inline"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <span v-if="!isOnline"
        >You're offline. Your reactions will be synced when you reconnect.</span
      >
      <span v-else-if="hasPendingReactions">Syncing your reactions...</span>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch, nextTick } from "vue";
import * as ReactionSystem from "@/wasm/reactionSystem";

export default {
  name: "SectionReactions",
  props: {
    sectionId: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    showCountOnEmpty: {
      type: Boolean,
      default: false,
    },
    showComments: {
      type: Boolean,
      default: false,
    },
    defaultExpanded: {
      type: Boolean,
      default: false, // This prop is no longer used but kept for backward compatibility
    },
  },
  emits: ["reactionUpdated"],
  setup(props, { emit }) {
    // State
    const showReactions = ref(true); // Always true now
    const reactions = ref([]);
    const userReactions = ref([]);
    const localUserReactions = ref([]);
    const pendingReactions = ref([]);
    const isOnline = ref(navigator.onLine);
    const initialized = ref(false);
    const lastSyncAttempt = ref(null);
    const loading = ref(false);
    const reacting = ref(false);

    // Prevent multiple fast toggles
    const isTogglingReaction = ref(false);

    // Computed
    const reactionOptions = computed(() => {
      const allTypes = Object.values(ReactionSystem.REACTION_TYPES);
      // Filter out the CONFUSED reaction type
      return allTypes.filter((type) => type.id !== "confused");
    });

    const reactionCounts = computed(() => {
      const counts = {};

      // Count reactions in the current reactions array
      reactions.value.forEach((reaction) => {
        if (!counts[reaction.type]) {
          counts[reaction.type] = 0;
        }
        counts[reaction.type]++;
      });

      // Ensure all reaction types that the user has locally selected are included,
      // even if they haven't shown up in the main reactions array yet
      localUserReactions.value.forEach((type) => {
        if (!counts[type]) {
          counts[type] = 1; // At least this user has it
        }
      });

      return counts;
    });

    const visibleReactionTypes = computed(() => {
      const result = [];

      Object.entries(reactionCounts.value).forEach(([type, count]) => {
        if (count > 0) {
          result.push({
            type,
            count,
            emoji: getReactionEmoji(type),
          });
        }
      });

      return result;
    });

    const hasReactions = computed(() => {
      return Object.values(reactionCounts.value).some((count) => count > 0);
    });

    const hasPendingReactions = computed(() => {
      return pendingReactions.value.length > 0;
    });

    // Methods
    const loadReactions = async () => {
      try {
        loading.value = true;

        // Ensure reaction system is initialized
        if (!initialized.value) {
          await ReactionSystem.initReactions();
          initialized.value = true;
        }

        // First, check pending reactions for this section
        const pendingReactionsData = ReactionSystem.getPendingReactions();
        const pendingForSection = pendingReactionsData.filter(
          (pr) => pr.sectionId === props.sectionId
        );

        // Log pending reactions for debugging
        if (pendingForSection.length > 0) {
          console.log(
            `Found ${pendingForSection.length} pending reactions for section ${props.sectionId}`,
            pendingForSection
          );
        }

        // Ensure we load reactions from storage to get the most up-to-date data
        await ReactionSystem.loadFromStorage();

        // Get reactions for this section
        const sectionReactions = ReactionSystem.getReactionsForSection(
          props.sectionId
        );

        // Make a deep copy to ensure Vue reactivity
        reactions.value = JSON.parse(JSON.stringify(sectionReactions || []));

        // Get current session ID
        const sessionId = getSessionId();
        console.log(`Current session ID: ${sessionId}`);

        // Check which reactions belong to us - look for userId prefix to support cross-device
        const userReactionsArray = reactions.value
          .filter((r) => {
            // If we have a user_ prefix, check if it matches the prefix in our sessionId
            if (
              sessionId.startsWith("user_") &&
              r.sessionId &&
              r.sessionId.startsWith("user_")
            ) {
              // Extract the userId part from both sessionIds
              const currentUserId = sessionId.split("_")[1];
              const reactionUserId = r.sessionId.split("_")[1];

              // If userIds match, this is from the same user on a different device
              return currentUserId === reactionUserId;
            }

            // Fall back to exact sessionId match
            return r.sessionId === sessionId;
          })
          .map((r) => r.type);

        if (userReactionsArray.length > 0) {
          console.log(
            `User ${sessionId} has ${
              userReactionsArray.length
            } reactions for section ${props.sectionId}: ${JSON.stringify(
              userReactionsArray
            )}`
          );
        }

        // Store user reactions
        userReactions.value = userReactionsArray;
        localUserReactions.value = [...userReactionsArray];

        // Check for any stored user intents and apply them immediately
        const userIntents = getUserReactionIntents();
        if (Object.keys(userIntents).length > 0) {
          console.log(
            `Found stored user intents for section ${props.sectionId}:`,
            userIntents
          );

          // Apply each stored intent
          Object.entries(userIntents).forEach(([reactionType, intent]) => {
            const { intent: action, timestamp } = intent;

            // Only apply if intent is recent (within last 10 minutes)
            const isRecent = Date.now() - timestamp < 600000; // 10 minutes

            if (isRecent) {
              if (
                action === "add" &&
                !localUserReactions.value.includes(reactionType)
              ) {
                // Add this reaction to local state (will be shown immediately)
                localUserReactions.value.push(reactionType);

                // Add optimistic reaction to the reactions array
                reactions.value.push({
                  id: `restored_${Date.now()}_${reactionType}`,
                  type: reactionType,
                  sessionId: sessionId,
                  timestamp: Date.now(),
                  isOptimistic: true,
                });

                console.log(
                  `Restored user intent to add ${reactionType} reaction`
                );
              } else if (action === "remove") {
                // Remove this reaction type from local state
                localUserReactions.value = localUserReactions.value.filter(
                  (r) => r !== reactionType
                );

                // Remove any matching reactions from the reactions array
                reactions.value = reactions.value.filter(
                  (r) => !(r.sessionId === sessionId && r.type === reactionType)
                );

                console.log(
                  `Restored user intent to remove ${reactionType} reaction`
                );
              }
            }
          });
        }

        console.log(
          `Loaded ${reactions.value.length} reactions for section ${props.sectionId}` +
            (userReactionsArray.length
              ? ` User has ${userReactionsArray.length} reactions`
              : "")
        );
      } catch (error) {
        console.error("Error loading reactions:", error);
      } finally {
        loading.value = false;
      }
    };

    const getSessionId = () => {
      return ReactionSystem.getSessionId();
    };

    const toggleReaction = async (reactionType) => {
      // Prevent multiple rapid toggles
      if (isTogglingReaction.value) {
        console.log("Ignoring toggle while previous toggle is in progress");
        return;
      }

      isTogglingReaction.value = true;

      try {
        // Check if reaction is already in local user reactions
        const index = localUserReactions.value.indexOf(reactionType);
        const isRemoving = index !== -1;
        const sessionId = getSessionId();

        console.log(
          `${
            isRemoving ? "Removing" : "Adding"
          } reaction ${reactionType} for section ${props.sectionId}`
        );

        // Create a temporary update to the counts for immediate UI feedback
        const tempReactions = [...reactions.value];

        // Track this reaction in a special field to preserve it during syncs
        // Create a reaction ID that's unique enough to identify this specific reaction
        const optimisticReactionId = `${sessionId}_${reactionType}_${Date.now()}`;

        if (isRemoving) {
          // REMOVING REACTION

          // First update local state before async operations
          localUserReactions.value = localUserReactions.value.filter(
            (r) => r !== reactionType
          );
          userReactions.value = userReactions.value.filter(
            (r) => r !== reactionType
          );

          // Immediately reflect in local UI by removing this user's reaction
          const reactionIndices = [];
          tempReactions.forEach((r, idx) => {
            if (r.sessionId === sessionId && r.type === reactionType) {
              reactionIndices.push(idx);
            }
          });

          // Remove in reverse order to not affect indices
          for (let i = reactionIndices.length - 1; i >= 0; i--) {
            tempReactions.splice(reactionIndices[i], 1);
          }

          reactions.value = tempReactions;

          // Also remove from pending if it exists there
          pendingReactions.value = pendingReactions.value.filter(
            (r) => r !== reactionType
          );

          // Create a removal reaction to send to the server
          const removalReaction = {
            id: optimisticReactionId,
            type: reactionType,
            sessionId: sessionId,
            timestamp: Date.now(),
          };

          // Add directly to the pending reactions queue using the exported function
          await ReactionSystem.addToPendingReactions(
            removalReaction,
            props.sectionId,
            "remove"
          );

          console.log(
            "Added removal to pending reactions using ReactionSystem.addToPendingReactions"
          );

          // Now perform the actual removal operation
          await removeReaction(reactionType, optimisticReactionId);

          // Try to sync immediately with the server if online
          if (isOnline.value) {
            await syncReactions();
          }
        } else {
          // ADDING REACTION

          // First update local state before async operations
          if (!localUserReactions.value.includes(reactionType)) {
            localUserReactions.value.push(reactionType);
          }

          if (!userReactions.value.includes(reactionType)) {
            userReactions.value.push(reactionType);
          }

          // Immediately reflect in local UI by adding this user's reaction
          tempReactions.push({
            id: optimisticReactionId,
            type: reactionType,
            sessionId: sessionId,
            timestamp: Date.now(),
            // Flag this reaction as optimistic so we can preserve it during syncs
            isOptimistic: true,
          });
          reactions.value = tempReactions;

          // Always add to pending first when offline
          if (
            !isOnline.value &&
            !pendingReactions.value.includes(reactionType)
          ) {
            pendingReactions.value.push(reactionType);
          }

          // Create a new reaction to send to the server
          const newReaction = {
            id: optimisticReactionId,
            type: reactionType,
            sessionId: sessionId,
            timestamp: Date.now(),
            comment: "",
          };

          // Add directly to the pending reactions queue using the exported function
          await ReactionSystem.addToPendingReactions(
            newReaction,
            props.sectionId,
            "add"
          );

          console.log(
            "Added new reaction to pending reactions using ReactionSystem.addToPendingReactions"
          );

          // Then try to add it to the system
          await addReaction(reactionType, optimisticReactionId);

          // Try to sync immediately with the server if online
          if (isOnline.value) {
            await syncReactions();
          }

          // If we're still offline after the add attempt, make sure it's in pending
          if (
            !isOnline.value &&
            !pendingReactions.value.includes(reactionType)
          ) {
            pendingReactions.value.push(reactionType);
          }
        }

        // Store this reaction in local storage so it persists across page refreshes
        preserveUserReactionIntent(
          reactionType,
          isRemoving,
          optimisticReactionId
        );

        // Refresh reaction counts from the backend to ensure consistency
        // Use a timeout to allow the async operations to complete
        // But make sure we merge optimistic changes
        setTimeout(() => refreshReactionCounts(true), 1500);
      } finally {
        // Reset toggle lock after a longer delay to prevent accidental double-clicks
        // and give more time for server operations to complete
        setTimeout(() => {
          isTogglingReaction.value = false;
        }, 800);
      }
    };

    /**
     * Store user reaction intent to preserve it during syncs
     */
    const preserveUserReactionIntent = (
      reactionType,
      isRemoving,
      reactionId
    ) => {
      try {
        // Get existing user intent cache
        const USER_INTENT_KEY = `user_reaction_intent_${props.sectionId}`;
        const existingData = localStorage.getItem(USER_INTENT_KEY);
        let userIntents = existingData ? JSON.parse(existingData) : {};

        // Update the intent
        userIntents[reactionType] = {
          intent: isRemoving ? "remove" : "add",
          timestamp: Date.now(),
          sessionId: getSessionId(),
          reactionId,
          // Add retry counter to track how many times we've retried
          retryCount: 0,
          // Add last checked timestamp
          lastChecked: Date.now(),
        };

        // Save back to storage
        localStorage.setItem(USER_INTENT_KEY, JSON.stringify(userIntents));

        console.log(
          `Preserved user intent for ${reactionType}: ${
            isRemoving ? "remove" : "add"
          }`
        );

        // Set up a retry mechanism for this intent
        setTimeout(() => {
          checkAndRetryUserIntent(reactionType);
        }, 5000); // Check after 5 seconds
      } catch (err) {
        console.warn("Failed to preserve user reaction intent:", err);
      }
    };

    /**
     * Check if a user intent needs to be retried and handle it
     */
    const checkAndRetryUserIntent = (reactionType) => {
      try {
        const userIntents = getUserReactionIntents();
        const intent = userIntents[reactionType];

        // If intent doesn't exist or is too old, nothing to do
        if (!intent || Date.now() - intent.timestamp > 300000) {
          return;
        }

        // Update last checked time
        intent.lastChecked = Date.now();

        // Check if reaction is in the expected state
        const isAddIntent = intent.intent === "add";
        const inUserReactions = localUserReactions.value.includes(reactionType);

        const needsRetry =
          (isAddIntent && !inUserReactions) ||
          (!isAddIntent && inUserReactions);

        if (needsRetry && intent.retryCount < 3) {
          console.log(
            `Retrying ${
              isAddIntent ? "add" : "remove"
            } for ${reactionType} (attempt ${intent.retryCount + 1})`
          );

          // Increment retry counter
          intent.retryCount++;

          // Re-save the intent with updated retry information
          const USER_INTENT_KEY = `user_reaction_intent_${props.sectionId}`;
          localStorage.setItem(USER_INTENT_KEY, JSON.stringify(userIntents));

          // Force a refresh with user intent preservation
          refreshReactionCounts(true);

          // If we're online, try to sync again
          if (isOnline.value) {
            syncReactions();
          }
        }

        // If still needs retrying, schedule another check later
        if (needsRetry && intent.retryCount < 3) {
          setTimeout(() => {
            checkAndRetryUserIntent(reactionType);
          }, 10000); // Wait longer between retries
        }
      } catch (err) {
        console.warn("Error checking/retrying user intent:", err);
      }
    };

    /**
     * Get user's reaction intents
     */
    const getUserReactionIntents = () => {
      try {
        const USER_INTENT_KEY = `user_reaction_intent_${props.sectionId}`;
        const existingData = localStorage.getItem(USER_INTENT_KEY);
        return existingData ? JSON.parse(existingData) : {};
      } catch (err) {
        console.warn("Failed to get user reaction intents:", err);
        return {};
      }
    };

    /**
     * Add a reaction to the section
     * @param {string} type - The reaction type to add
     * @param {string} reactionId - Optional ID for the reaction (for optimistic UI tracking)
     */
    const addReaction = async (type, reactionId = null) => {
      try {
        // If we're already reacting or have this reaction, return
        if (reacting.value) return;
        if (userReactions.value.includes(type)) return;

        // Set reacting state and provide immediate UI feedback
        reacting.value = true;
        localUserReactions.value.push(type);

        console.log(
          `Adding reaction ${type} for section ${props.sectionId}${
            reactionId ? " with ID: " + reactionId : ""
          }`
        );

        // Add the reaction to the reaction system
        const newReaction = await ReactionSystem.addReaction(
          props.sectionId,
          type
        );

        console.log(
          `Added reaction ${type} for section ${props.sectionId}`,
          newReaction
        );

        // CRITICAL FIX: Manually add to pending reactions for troubleshooting
        try {
          const sessionId = getSessionId();
          const timestamp = Date.now();
          const pendingKey = "woven_blog_pending_reactions";

          // Create the reaction object with proper structure
          const pendingReaction = {
            sectionId: props.sectionId,
            action: "add",
            timestamp: timestamp,
            sessionId: sessionId,
            synced: false,
            reactionData: {
              id: reactionId || `${sessionId}_${type}_${timestamp}`,
              type: type,
              timestamp: timestamp,
              sessionId: sessionId,
            },
          };

          // Add to pending reactions manually
          let pendingReactions = JSON.parse(
            localStorage.getItem(pendingKey) || "[]"
          );
          pendingReactions.push(pendingReaction);
          localStorage.setItem(pendingKey, JSON.stringify(pendingReactions));

          console.log(
            "Manually added to pending reactions queue:",
            pendingReaction
          );

          // Force a sync if online
          if (isOnline.value) {
            console.log("Forcing immediate sync...");
            setTimeout(() => syncReactions(), 100);
          }
        } catch (pendingError) {
          console.error(
            "Failed to manually add to pending queue:",
            pendingError
          );
        }
        // END CRITICAL FIX

        // Refresh the data after adding, but preserve optimistic changes
        await refreshReactionCounts(true);

        // Update user reactions
        if (!userReactions.value.includes(type)) {
          userReactions.value.push(type);
        }

        await nextTick(); // Ensure the UI has updated

        emit("reactionUpdated", {
          type,
          operation: "add",
          sectionId: props.sectionId,
        });
      } catch (error) {
        console.error("Error adding reaction:", error);
        // Remove from local array if failed
        const index = localUserReactions.value.indexOf(type);
        if (index !== -1) {
          localUserReactions.value.splice(index, 1);
        }
      } finally {
        reacting.value = false;
      }
    };

    const removeReaction = async (reactionType, reactionId = null) => {
      try {
        const result = await ReactionSystem.removeReaction(
          props.sectionId,
          reactionType
        );

        // Log success for debugging
        console.log(
          `Removed reaction ${reactionType} for section ${props.sectionId}${
            reactionId ? " with ID: " + reactionId : ""
          }`,
          result
        );

        // CRITICAL FIX: Manually add removal to pending reactions queue
        try {
          const sessionId = getSessionId();
          const timestamp = Date.now();
          const pendingKey = "woven_blog_pending_reactions";

          // Create the removal action with proper structure
          const pendingRemoval = {
            sectionId: props.sectionId,
            action: "remove",
            timestamp: timestamp,
            sessionId: sessionId,
            synced: false,
            reactionData: {
              id: reactionId || `${sessionId}_${reactionType}_${timestamp}`,
              type: reactionType,
              timestamp: timestamp,
              sessionId: sessionId,
            },
          };

          // Add to pending reactions manually
          let pendingReactions = JSON.parse(
            localStorage.getItem(pendingKey) || "[]"
          );
          pendingReactions.push(pendingRemoval);
          localStorage.setItem(pendingKey, JSON.stringify(pendingReactions));

          console.log(
            "Manually added removal to pending reactions queue:",
            pendingRemoval
          );
        } catch (pendingError) {
          console.error(
            "Failed to manually add removal to pending queue:",
            pendingError
          );
        }
        // END CRITICAL FIX

        // When online, try to sync immediately
        if (isOnline.value) {
          await syncReactions();
        }

        return true;
      } catch (error) {
        console.error("Failed to remove reaction:", error);
        return false;
      }
    };

    const syncReactions = async () => {
      if (!isOnline.value || !initialized.value) return;

      lastSyncAttempt.value = Date.now();

      try {
        // Log pending reactions before sync
        const pendingBefore = localStorage.getItem(
          "woven_blog_pending_reactions"
        );
        console.log(
          "Pending reactions before sync:",
          pendingBefore ? JSON.parse(pendingBefore) : []
        );

        // This will call into reactionSystem's syncReactions method
        console.log("Starting reaction sync...");
        const result = await ReactionSystem.syncReactions();
        console.log("Sync result:", result);

        if (!result || !result.success) {
          console.warn(
            "Reaction sync was not successful:",
            result?.reason || "Unknown error"
          );
          // Don't clear pending reactions if sync failed
          return;
        }

        // Update pending reactions after sync
        const pendingData = ReactionSystem.getPendingReactions();
        const pendingSectionReactions = pendingData.filter(
          (p) => p.sectionId === props.sectionId
        );

        // Log pending reactions after sync
        console.log("Pending reactions after sync:", pendingData);
        console.log(
          "Pending reactions for this section after sync:",
          pendingSectionReactions
        );

        // Update our local pending array
        pendingReactions.value = pendingSectionReactions.map((p) => p.type);

        console.log(
          `Updated pending reactions for section ${props.sectionId}:`,
          pendingReactions.value
        );

        // Refresh the data after syncing
        await refreshReactionCounts();

        emit("reactionUpdated", {
          operation: "sync",
          sectionId: props.sectionId,
        });
      } catch (error) {
        console.error("Error during reaction sync:", error);
      }
    };

    /**
     * Refresh only the reaction counts without updating local user reactions
     * @param {boolean} preserveUserIntent - Whether to preserve user's intended reactions
     */
    const refreshReactionCounts = async (preserveUserIntent = false) => {
      try {
        // Ensure the reaction system is initialized
        if (!initialized.value) {
          await ReactionSystem.initReactions();
          initialized.value = true;
        }

        // Get fresh reactions for this section - force a reload from storage
        // rather than relying on whatever is in the cache
        try {
          // Force a reload from storage
          await ReactionSystem.loadFromStorage();

          // Get the latest reactions for this section
          const freshReactions = ReactionSystem.getReactionsForSection(
            props.sectionId
          );

          // Create a deep copy to ensure Vue reactivity
          const reactionsCopy = JSON.parse(JSON.stringify(freshReactions));

          // Only update if we have something meaningful
          if (Array.isArray(reactionsCopy)) {
            // If we need to preserve user intent, we'll merge the server data with local intents
            if (preserveUserIntent) {
              const userIntents = getUserReactionIntents();
              const sessionId = getSessionId();

              // Find and keep any optimistic reactions in the current view
              const optimisticReactions = reactions.value.filter(
                (r) => r.isOptimistic && r.sessionId === sessionId
              );

              // Process each reaction type with user intent
              Object.entries(userIntents).forEach(([reactionType, intent]) => {
                const {
                  intent: action,
                  timestamp,
                  sessionId: intentSessionId,
                } = intent;

                // Only apply if the intent is recent (last 5 minutes instead of 30 seconds)
                const isRecent = Date.now() - timestamp < 300000; // 5 minutes in milliseconds

                if (isRecent && intentSessionId === sessionId) {
                  if (action === "add") {
                    // If the user wants to add this reaction but it's not in the server data,
                    // add an optimistic entry to preserve it
                    const reactionExists = reactionsCopy.some(
                      (r) =>
                        r.sessionId === sessionId && r.type === reactionType
                    );

                    if (!reactionExists) {
                      // Add from our optimistic set if available
                      const existingOptimistic = optimisticReactions.find(
                        (r) => r.type === reactionType
                      );

                      if (existingOptimistic) {
                        reactionsCopy.push(existingOptimistic);
                      } else {
                        // Create a new optimistic reaction
                        reactionsCopy.push({
                          id: `optimistic_${Date.now()}_${reactionType}`,
                          type: reactionType,
                          sessionId,
                          timestamp: Date.now(),
                          isOptimistic: true,
                        });
                      }

                      // Make sure it's in local user reactions
                      if (!localUserReactions.value.includes(reactionType)) {
                        localUserReactions.value.push(reactionType);
                      }
                    }
                  } else if (action === "remove") {
                    // If the user wants to remove this reaction but it's still in the server data,
                    // filter it out
                    const reactionIndex = reactionsCopy.findIndex(
                      (r) =>
                        r.sessionId === sessionId && r.type === reactionType
                    );

                    if (reactionIndex !== -1) {
                      reactionsCopy.splice(reactionIndex, 1);
                    }

                    // Make sure it's not in local user reactions
                    localUserReactions.value = localUserReactions.value.filter(
                      (r) => r !== reactionType
                    );
                  }
                }
              });

              console.log(
                "Applied user intent to server reactions:",
                `Server had ${freshReactions.length}, ` +
                  `After merging has ${reactionsCopy.length}`
              );
            }

            // Now update the reactions view with our potentially modified data
            reactions.value = reactionsCopy;

            // Update the user reactions list based on server data and our intent
            const sessionId = getSessionId();
            const userReactionsArray = freshReactions
              .filter((r) => {
                // If we have a user_ prefix, check if it matches the prefix in our sessionId
                if (
                  sessionId.startsWith("user_") &&
                  r.sessionId &&
                  r.sessionId.startsWith("user_")
                ) {
                  // Extract the userId part from both sessionIds
                  const currentUserId = sessionId.split("_")[1];
                  const reactionUserId = r.sessionId.split("_")[1];

                  // If userIds match, this is from the same user on a different device
                  return currentUserId === reactionUserId;
                }

                // Fall back to exact sessionId match
                return r.sessionId === sessionId;
              })
              .map((r) => r.type);

            // If preserving user intent, only use the server responses to ADD to local state
            // never remove reactions that the user has explicitly added
            if (preserveUserIntent) {
              // For each local reaction not in the server response, check if we have
              // an active "add" intent for it before removing
              localUserReactions.value.forEach((type) => {
                const userIntents = getUserReactionIntents();
                const intent = userIntents[type];

                if (
                  intent &&
                  intent.intent === "add" &&
                  userReactionsArray.indexOf(type) === -1
                ) {
                  // This is a reaction the user wants to keep but isn't on the server yet
                  // Add it to the array to preserve it
                  userReactionsArray.push(type);
                }
              });
            }

            // Update user reactions
            userReactions.value = userReactionsArray;

            // Only completely overwrite local state if not preserving user intent
            if (!preserveUserIntent) {
              localUserReactions.value = [...userReactionsArray];
            }

            console.log(
              `Updated reaction count: ${reactions.value.length} for ${
                props.sectionId
              }, user reactions: ${localUserReactions.value.join(", ")}`
            );
          } else {
            console.warn(
              `Invalid reactions data for section ${props.sectionId}:`,
              reactionsCopy
            );
          }
        } catch (error) {
          console.warn("Error refreshing reactions from storage:", error);
        }
      } catch (error) {
        console.error("Error refreshing reaction counts:", error);
      }
    };

    const getReactionEmoji = (reactionType) => {
      const reaction = Object.values(ReactionSystem.REACTION_TYPES).find(
        (r) => r.id === reactionType
      );

      return reaction ? reaction.emoji : "👍";
    };

    // Watch for online status changes
    watch(
      () => navigator.onLine,
      (online) => {
        isOnline.value = online;
        if (online) {
          // Sync reactions when coming back online
          syncReactions();
        }
      }
    );

    // Initialize
    onMounted(async () => {
      // Set initial online state
      isOnline.value = navigator.onLine;

      // Initialize the component
      await loadReactions();

      // Initialize pending reactions
      const pendingData = ReactionSystem.getPendingReactions();
      const pendingSectionReactions = pendingData.filter(
        (item) => item.sectionId === props.sectionId
      );

      pendingReactions.value = pendingSectionReactions
        .filter((item) => item.action === "add")
        .map((item) => {
          // Handle both old format 'reaction' and new format 'reactionData'
          const reactionObj = item.reactionData || item.reaction;
          return reactionObj?.type;
        });

      if (pendingReactions.value.length > 0) {
        console.log(
          `Found ${pendingReactions.value.length} pending reactions during initialization`
        );
      }

      // Ensure the reaction system has been initialized
      initialized.value = true;

      // Listen for online/offline events
      window.addEventListener("online", () => {
        isOnline.value = true;
        syncReactions();
      });

      window.addEventListener("offline", () => {
        isOnline.value = false;
      });

      // Set a periodic refresh for reaction counts
      const refreshInterval = setInterval(async () => {
        if (isOnline.value) {
          // Always preserve user intent during periodic refreshes
          await refreshReactionCounts(true);

          // Try to sync in case there are pending reactions
          if (hasPendingReactions.value) {
            syncReactions();
          }
        }
      }, 10000); // Every 10 seconds

      // Register this interval with the reaction system for centralized cleanup
      if (typeof ReactionSystem.registerInterval === "function") {
        ReactionSystem.registerInterval(refreshInterval);
      }

      // Clean up interval on component unmount
      return () => {
        clearInterval(refreshInterval);
      };
    });

    return {
      showReactions,
      reactions,
      userReactions,
      localUserReactions,
      pendingReactions,
      reactionOptions,
      reactionCounts,
      visibleReactionTypes,
      hasReactions,
      hasPendingReactions,
      isOnline,
      toggleReaction,
      getReactionEmoji,
      initialized,
      loading,
      reacting,
    };
  },
};
</script>

<style scoped>
.section-reactions {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid rgba(148, 163, 184, 0.2);
}

.reaction-button {
  white-space: nowrap;
}

.reaction-badge {
  white-space: nowrap;
}
</style>
