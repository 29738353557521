/**
 * Reader Reactions System
 *
 * Allows readers to leave reactions or "tags" on specific sections of content
 * and see others' reactions. Works offline and syncs when back online.
 */

import { syncWithServer, isOnline, getSessionId } from "./offlineSync";

// Reaction Types
export const REACTION_TYPES = {
  INSIGHTFUL: { id: "insightful", emoji: "💡", label: "Insightful" },
  AGREE: { id: "agree", emoji: "👍", label: "Like" },
  LOVE: { id: "love", emoji: "❤️", label: "Love This" },
  QUESTION: { id: "question", emoji: "❓", label: "Question" },
};

// Local storage keys
const REACTIONS_STORAGE_KEY = "woven_blog_reactions";
const PENDING_REACTIONS_KEY = "woven_blog_pending_reactions";

// In-memory cache
let reactionsCache = null;
let initialized = false;
let systemIntervalIds = []; // Track any intervals created by the reaction system

/**
 * Initialize the reaction system
 */
export async function initReactions() {
  if (initialized) return;

  // Make sure the system intervals array exists
  if (!systemIntervalIds) {
    systemIntervalIds = [];
  }

  // Initialize the reactions cache if needed
  if (!reactionsCache) {
    reactionsCache = { sections: {} };
  }

  // Pre-register known section IDs to ensure they're available for fetching
  // This prevents the race condition where sections aren't registered before the first fetch
  const knownSectionIds = [
    "visualization",
    "intro",
    "why-the-buzz",
    "real-world",
    "resources",
    "conclusion",
  ];

  // Ensure each known section exists in the cache
  knownSectionIds.forEach((sectionId) => {
    if (!reactionsCache.sections[sectionId]) {
      reactionsCache.sections[sectionId] = [];
    }
  });

  // Load reactions from local storage
  await loadFromStorage();

  // Log the cached reactions for debugging
  const sectionIds = Object.keys(reactionsCache.sections || {});
  console.log(
    `Loaded reactions for ${sectionIds.length} sections from storage`,
    sectionIds.map((id) => ({
      section: id,
      count: reactionsCache.sections[id].length,
      user: reactionsCache.sections[id].filter(
        (r) => r.sessionId === getSessionId()
      ).length,
    }))
  );

  // Try to sync with server if online
  if (isOnline()) {
    try {
      await syncReactions();
    } catch (error) {
      console.warn("Failed to sync reactions with server:", error);
    }
  }

  initialized = true;
}

/**
 * Load reactions from local storage
 */
export async function loadFromStorage() {
  try {
    const storedData = localStorage.getItem(REACTIONS_STORAGE_KEY);
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);

        // Log the loaded data for debugging
        console.log(`Loaded reaction data from storage`);

        // Initialize the reactions cache properly
        if (!reactionsCache) {
          reactionsCache = { sections: {} };
        }

        // Check if the data is already in the expected format or needs conversion
        if (parsedData.sections) {
          reactionsCache = parsedData;
        } else {
          // Data is in the old format, convert it
          reactionsCache.sections = parsedData;
        }

        // Calculate total reaction count for debugging
        let totalReactions = 0;
        let sectionCount = 0;

        for (const sectionId in reactionsCache.sections) {
          if (Array.isArray(reactionsCache.sections[sectionId])) {
            totalReactions += reactionsCache.sections[sectionId].length;
            sectionCount++;
          }
        }

        console.log(
          `Loaded ${totalReactions} reactions across ${sectionCount} sections from storage`
        );

        // Load pending reactions if they exist
        const pendingData = localStorage.getItem(PENDING_REACTIONS_KEY);
        if (pendingData) {
          try {
            const pendingReactions = JSON.parse(pendingData);
            console.log(
              `Loaded ${pendingReactions.length} pending reactions from storage`
            );

            // Store the pending reactions separately as expected by the rest of the code
            // Don't add them to the reactionsCache object
          } catch (pendingError) {
            console.warn(
              "Error parsing pending reactions from storage:",
              pendingError
            );
          }
        }

        return reactionsCache;
      } catch (parseError) {
        console.error("Error parsing reactions from storage:", parseError);
        // Reset cache on error
        reactionsCache = { sections: {} };
        return reactionsCache;
      }
    } else {
      console.log("No reactions found in storage");
      // No data in storage, reset cache
      reactionsCache = { sections: {} };
      return reactionsCache;
    }
  } catch (error) {
    console.error("Error loading reactions from storage:", error);
    // Reset cache on error
    reactionsCache = { sections: {} };
    return reactionsCache;
  }
}

/**
 * Save reactions to local storage
 */
async function saveToStorage() {
  try {
    // Ensure the cache has a sections property
    if (!reactionsCache) {
      reactionsCache = { sections: {} };
    }
    if (!reactionsCache.sections) {
      reactionsCache.sections = {};
    }

    // Save directly to localStorage for immediate persistence
    localStorage.setItem(REACTIONS_STORAGE_KEY, JSON.stringify(reactionsCache));

    // Log successful save for debugging
    const totalReactions = Object.values(reactionsCache.sections || {}).reduce(
      (total, sectionReactions) => total + sectionReactions.length,
      0
    );

    console.log(
      `Successfully saved ${totalReactions} reactions to localStorage across ${
        Object.keys(reactionsCache.sections || {}).length
      } sections`
    );
    return true;
  } catch (error) {
    console.error("Failed to save reactions to storage:", error);
    return false;
  }
}

/**
 * Get all reactions for a specific section
 * @param {string} sectionId - The ID of the section
 * @returns {Array} - Array of reaction objects
 */
export function getReactionsForSection(sectionId) {
  // Make sure we're initialized
  if (!initialized) {
    console.warn("Reaction system not initialized when getReactions called");
    // Return empty array if not initialized yet
    if (!reactionsCache) {
      return [];
    }
  }

  // Check if the section exists in the cache
  if (!reactionsCache || !reactionsCache.sections[sectionId]) {
    // Create the section if it doesn't exist
    if (reactionsCache && !reactionsCache.sections[sectionId]) {
      reactionsCache.sections[sectionId] = [];
    } else {
      return [];
    }
  }

  // Make a fresh copy to ensure reactivity works as expected
  return reactionsCache.sections[sectionId].slice();
}

/**
 * Add a new reaction to a section
 * @param {string} sectionId - The ID of the section
 * @param {string} reactionType - The type of reaction
 * @param {string} comment - Optional comment with the reaction
 */
export async function addReaction(sectionId, reactionType, comment = "") {
  if (!initialized) {
    await initReactions();
  }

  // Make sure the section exists in the cache
  if (!reactionsCache.sections[sectionId]) {
    reactionsCache.sections[sectionId] = [];
  }

  // Get the session ID for the current user
  const sessionId = getSessionId();

  // Check if user already reacted with this type
  const existingReactionIndex = reactionsCache.sections[sectionId].findIndex(
    (r) => r.sessionId === sessionId && r.type === reactionType
  );

  const timestamp = Date.now();

  // Create the reaction object
  const reaction = {
    id: `reaction_${timestamp}_${Math.random().toString(36).substring(2, 10)}`,
    type: reactionType,
    comment: comment,
    timestamp: timestamp,
    sessionId: sessionId,
  };

  // If user already reacted, update the reaction, otherwise add a new one
  if (existingReactionIndex !== -1) {
    reactionsCache.sections[sectionId][existingReactionIndex] = reaction;
  } else {
    reactionsCache.sections[sectionId].push(reaction);
  }

  console.log(
    `Added reaction to storage for section '${sectionId}':`,
    reaction
  );

  // Save to local storage BEFORE adding to pending reactions
  await saveToStorage();

  try {
    // Add to pending reactions for future sync
    await addToPendingReactions(reaction, sectionId, "add");

    // Try to sync immediately if online
    if (isOnline()) {
      try {
        const syncResult = await syncReactions();
        if (!syncResult.success) {
          console.warn(
            "Failed to sync reaction immediately:",
            syncResult.reason
          );
        }
      } catch (syncError) {
        console.warn("Failed to sync reactions with server:", syncError);
      }
    }
  } catch (pendingError) {
    console.warn(
      "Error adding to pending reactions, but reaction still saved locally:",
      pendingError
    );
  }

  // Double-check the reaction is still in storage after all operations
  const checkReactions = getReactionsForSection(sectionId);
  const stillExists = checkReactions.some(
    (r) => r.sessionId === sessionId && r.type === reactionType
  );

  if (!stillExists) {
    console.warn("Reaction was lost during operations - re-adding it");
    // Re-add if somehow lost
    reactionsCache.sections[sectionId].push(reaction);
    await saveToStorage();
  }

  return reaction;
}

/**
 * Remove a reaction from a section
 * @param {string} sectionId - The ID of the section
 * @param {string} reactionType - The type of reaction to remove
 */
export async function removeReaction(sectionId, reactionType) {
  if (!initialized) {
    await initReactions();
  }

  // Make sure the section exists in the cache
  if (!reactionsCache.sections[sectionId]) {
    return false; // Nothing to remove
  }

  // Get the session ID for the current user
  const sessionId = getSessionId();

  // Find the user's reaction of this type
  const existingReactionIndex = reactionsCache.sections[sectionId].findIndex(
    (r) => r.sessionId === sessionId && r.type === reactionType
  );

  // If reaction doesn't exist, nothing to do
  if (existingReactionIndex === -1) {
    return false;
  }

  // Store the reaction for sync
  const removedReaction =
    reactionsCache.sections[sectionId][existingReactionIndex];

  // Remove the reaction from the array
  reactionsCache.sections[sectionId].splice(existingReactionIndex, 1);

  // Save to local storage
  await saveToStorage();

  // Add to pending reactions for future sync
  await addToPendingReactions(removedReaction, sectionId, "remove");

  // Try to sync immediately if online
  if (isOnline()) {
    try {
      await syncReactions();
    } catch (error) {
      console.warn("Failed to sync reactions with server:", error);
    }
  }

  return true;
}

/**
 * Add a reaction to the pending queue for future sync
 * @param {Object} reaction - The reaction object
 * @param {string} sectionId - The section ID
 * @param {string} action - The action to perform ("add" or "remove")
 */
export async function addToPendingReactions(
  reaction,
  sectionId,
  action = "add"
) {
  try {
    let pendingReactions = [];

    // Get existing pending reactions
    const storedPending = localStorage.getItem(PENDING_REACTIONS_KEY);

    if (storedPending) {
      try {
        pendingReactions = JSON.parse(storedPending);
      } catch (e) {
        console.error("Failed to parse pending reactions:", e);
        pendingReactions = [];
      }
    }

    // Ensure reaction has all required fields for API
    const enhancedReaction = { ...reaction };

    // Make sure the reaction has an ID
    if (!enhancedReaction.id) {
      enhancedReaction.id = `reaction_${Date.now()}_${Math.random()
        .toString(36)
        .substring(2, 10)}`;
    }

    // Ensure it has a timestamp
    if (!enhancedReaction.timestamp) {
      enhancedReaction.timestamp = Date.now();
    }

    // Ensure it has a comment field even if empty
    if (enhancedReaction.comment === undefined) {
      enhancedReaction.comment = "";
    }

    // Add the new pending reaction with complete structure
    pendingReactions.push({
      reactionData: enhancedReaction,
      sectionId,
      action,
      timestamp: Date.now(),
      synced: false,
    });

    // Save back to localStorage
    localStorage.setItem(
      PENDING_REACTIONS_KEY,
      JSON.stringify(pendingReactions)
    );

    console.log(
      `Added reaction to pending queue for section ${sectionId}. Total pending: ${pendingReactions.length}`,
      enhancedReaction
    );

    return true;
  } catch (error) {
    console.error("Failed to add to pending reactions:", error);
    return false;
  }
}

/**
 * Get all pending reactions
 * @returns {Array} - Array of pending reaction objects
 */
export function getPendingReactions() {
  try {
    const pendingReactionsData = localStorage.getItem(PENDING_REACTIONS_KEY);

    if (!pendingReactionsData) {
      return [];
    }

    const pendingReactions = JSON.parse(pendingReactionsData);
    return Array.isArray(pendingReactions) ? pendingReactions : [];
  } catch (error) {
    console.error("Failed to get pending reactions:", error);
    return [];
  }
}

/**
 * Sync reactions with the server
 * @returns {Promise<Object>} Result of the sync operation
 */
export async function syncReactions() {
  try {
    // Get pending reactions from localStorage
    const pendingReactionsData = localStorage.getItem(PENDING_REACTIONS_KEY);

    // First, try to fetch all reactions from server regardless of pending status
    // This ensures we get other users' reactions too
    try {
      await fetchReactionsFromServer();
      console.log("Fetched latest reactions from server");
    } catch (fetchError) {
      console.warn("Failed initial fetch of reactions:", fetchError);
    }

    if (!pendingReactionsData) {
      console.log("No pending reactions to sync");
      return { success: true, syncedCount: 0 };
    }

    let pendingReactions = [];
    try {
      pendingReactions = JSON.parse(pendingReactionsData);

      // Ensure it's an array
      if (!Array.isArray(pendingReactions)) {
        console.warn("Pending reactions data is not an array, resetting");
        localStorage.setItem(PENDING_REACTIONS_KEY, JSON.stringify([]));
        return { success: false, reason: "invalid_data" };
      }

      // Ensure all items have the correct structure for the server
      pendingReactions = pendingReactions.map((item) => {
        // Convert reaction to reactionData if needed
        if (item.reaction && !item.reactionData) {
          item.reactionData = item.reaction;
          delete item.reaction;
        }
        return item;
      });
    } catch (e) {
      console.error("Failed to parse pending reactions:", e);
      localStorage.setItem(PENDING_REACTIONS_KEY, JSON.stringify([]));
      return { success: false, reason: "invalid_data" };
    }

    if (pendingReactions.length === 0) {
      console.log("No pending reactions to sync (empty array)");
      return { success: true, syncedCount: 0 };
    }

    console.log(
      `Attempting to sync ${pendingReactions.length} pending reactions`
    );

    // Make sure we're online
    if (!isOnline()) {
      console.log("Cannot sync reactions while offline");
      return {
        success: false,
        reason: "offline",
        pendingCount: pendingReactions.length,
      };
    }

    try {
      // Call the sync function
      const result = await syncWithServer(pendingReactions);

      if (result.success) {
        // Create a backup of the reactions before clearing pending
        const reactionsBackup = JSON.parse(JSON.stringify(reactionsCache));

        // Clear pending reactions
        localStorage.setItem(PENDING_REACTIONS_KEY, JSON.stringify([]));
        console.log(
          `Successfully synced ${result.syncedCount} reactions with the server`
        );

        // In all environments, fetch the latest reactions to ensure we have reactions from all users
        try {
          await fetchReactionsFromServer();
        } catch (fetchError) {
          console.warn(
            "Failed to fetch updated reactions, but sync was successful:",
            fetchError
          );

          // If fetch fails, restore from backup to ensure we don't lose reactions
          reactionsCache = reactionsBackup;
          await saveToStorage();
        }

        return { success: true, syncedCount: result.syncedCount };
      } else {
        console.warn("Failed to sync reactions:", result.reason);
        return {
          success: false,
          reason: result.reason,
          pendingCount: pendingReactions.length,
        };
      }
    } catch (syncError) {
      console.error("Error during sync request:", syncError);
      return {
        success: false,
        reason: syncError.message || "network_error",
        pendingCount: pendingReactions.length,
      };
    }
  } catch (error) {
    console.error("Error in syncReactions function:", error);
    return { success: false, reason: error.message };
  }
}

/**
 * Fetch reactions from the server for all sections
 */
async function fetchReactionsFromServer() {
  if (!isOnline()) return;

  try {
    // Create a backup of the reactions before fetch
    const reactionsBackup = JSON.parse(JSON.stringify(reactionsCache));

    // Import the offlineSync module to get the API_ENDPOINT
    const { API_ENDPOINT } = await import("./offlineSync");

    // Check if API_ENDPOINT was properly imported
    if (!API_ENDPOINT) {
      console.warn("API_ENDPOINT not available in offlineSync module");
      return;
    }

    // Log the sessionId for debugging
    const sessionId = getSessionId();
    console.log(`Fetching reactions for session: ${sessionId}`);

    // Get all section IDs from the cache
    const sectionIds = Object.keys(reactionsCache.sections);
    console.log(
      `Fetching reactions for ${sectionIds.length} sections: ${sectionIds.join(
        ", "
      )}`
    );

    // If no sections, retry after a delay (race condition)
    if (sectionIds.length === 0) {
      console.log(
        "No sections found for fetching reactions, scheduling retry in 1.5 seconds"
      );
      setTimeout(() => fetchReactionsFromServer(), 1500);
      return;
    }

    let fetchSuccess = false;
    let fetchedReactionsCount = 0;

    // NEW APPROACH: Fetch all sections in parallel
    const fetchPromises = sectionIds.map(async (sectionId) => {
      try {
        // Use the API_ENDPOINT with safe string replacement
        const baseEndpoint = API_ENDPOINT.includes("/sync")
          ? API_ENDPOINT.replace("/sync", "/reactions/section")
          : `${API_ENDPOINT}/reactions/section`;

        const apiUrl = `${baseEndpoint}?id=${encodeURIComponent(sectionId)}`;
        console.log(
          `Fetching reactions for section ${sectionId} from ${apiUrl}`
        );

        const response = await fetch(apiUrl);

        if (!response.ok) {
          console.warn(
            `Failed to fetch reactions for section ${sectionId}: ${response.status}`
          );
          return null;
        }

        const data = await response.json();
        console.log(`Server response for section ${sectionId}:`, data);

        if (data.success && Array.isArray(data.reactions)) {
          return { sectionId, data };
        }
        return null;
      } catch (sectionError) {
        console.warn(
          `Failed to fetch reactions for section ${sectionId}:`,
          sectionError
        );
        return null;
      }
    });

    // Wait for all fetches to complete
    const results = await Promise.all(fetchPromises);

    // Process the results
    results.forEach((result) => {
      if (result) {
        const { sectionId, data } = result;
        // Always update with the server data, even if empty,
        // to ensure we have the latest state
        reactionsCache.sections[sectionId] = data.reactions;
        fetchedReactionsCount += data.reactions.length;
        fetchSuccess = true;

        // Log reactions from this user for debugging
        const userReactions = data.reactions.filter((r) => {
          if (
            sessionId.startsWith("user_") &&
            r.sessionId &&
            r.sessionId.startsWith("user_")
          ) {
            // Extract the userId part from both sessionIds
            const currentUserId = sessionId.split("_")[1];
            const reactionUserId = r.sessionId.split("_")[1];

            return currentUserId === reactionUserId;
          }
          return r.sessionId === sessionId;
        });

        if (userReactions.length > 0) {
          console.log(
            `Found ${userReactions.length} reactions from this user in section ${sectionId}`
          );
        }
      }
    });

    // If we didn't get any reactions from the server, but had reactions locally,
    // restore the backup to avoid data loss
    if (
      !fetchSuccess &&
      Object.values(reactionsBackup.sections).some((arr) => arr.length > 0)
    ) {
      console.warn(
        "Server returned no reactions but we had local reactions - preserving local data"
      );
      reactionsCache = reactionsBackup;
    }

    // Log the result of the fetch
    if (fetchSuccess) {
      console.log(
        `Successfully fetched ${fetchedReactionsCount} reactions from server`
      );
    } else {
      console.log("No reactions fetched from server");
    }

    // Save updated cache
    await saveToStorage();
  } catch (error) {
    console.error("Failed to fetch reactions from server:", error);
  }
}

/**
 * Export the getSessionId function to ensure consistent
 * session IDs are used across the application
 */
export { getSessionId } from "./offlineSync";

/**
 * Clean up all reaction system resources
 * This should be called when navigating away from pages using the reaction system
 */
export function cleanupReactionSystem() {
  console.log("Cleaning up reaction system resources");

  // Clear any intervals that were tracked
  if (systemIntervalIds && systemIntervalIds.length > 0) {
    console.log(
      `Clearing ${systemIntervalIds.length} reaction system intervals`
    );
    systemIntervalIds.forEach((intervalId) => {
      clearInterval(intervalId);
    });
    systemIntervalIds = [];
  }

  // Reset the initialized flag to ensure proper re-initialization if needed
  initialized = false;

  // Clear the reactions cache to free memory
  reactionsCache = null;

  console.log("Reaction system cleanup complete");
  return true;
}

/**
 * Register an interval with the reaction system for centralized cleanup
 * @param {number} intervalId - The interval ID to track
 */
export function registerInterval(intervalId) {
  if (!systemIntervalIds) {
    systemIntervalIds = [];
  }

  if (intervalId && !systemIntervalIds.includes(intervalId)) {
    systemIntervalIds.push(intervalId);
    console.log(
      `Registered interval ${intervalId} with reaction system. Total: ${systemIntervalIds.length}`
    );
  }
}
