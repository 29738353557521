<template>
  <div
    class="flex justify-center items-center px-4 sm:px-6 md:px-10 py-10 md:py-20"
  >
    <div class="w-full max-w-3xl mt-4 sm:mt-8">
      <a
        href="/blog"
        class="uppercase tracking-widest font-semibold text-woven-light-green"
        >← Back to Insights</a
      >
      <div class="flex flex-wrap items-center gap-2 mt-4 sm:mt-8">
        <span
          v-for="category in post.categories"
          :key="category"
          class="px-3 py-2 bg-woven-green text-black rounded-full text-sm"
        >
          {{ post._embedded["wp:term"][0][0].name }}
        </span>
      </div>
      <h1
        class="text-3xl sm:text-4xl md:text-5xl font-medium text-white mt-6 md:mt-8 mb-0"
      >
        Building Blocks for the Future Web
      </h1>
      <div class="flex flex-wrap items-center text-slate-400 mt-2 md:mt-3 mb-4">
        <p class="text-sm md:text-base italic mr-4">
          {{ formattedDate }} &mdash; Kevin Behr, Software Development Thread
          Lead
        </p>
        <div class="flex items-center mt-1 sm:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 mr-1 text-woven-light-green"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span class="text-sm">{{ readingTime }} min read</span>
        </div>
      </div>

      <!-- Featured Visualization -->
      <div class="mt-6 mb-8 md:mb-10">
        <!-- Visualization Title -->
        <h3
          class="text-lg md:text-xl font-semibold text-woven-light-green mb-3 text-center"
        >
          Visitor Impression Map
        </h3>

        <!-- Visualization Stats -->
        <div class="grid grid-cols-2 gap-2 mb-3">
          <div class="bg-slate-800 rounded p-2 text-center">
            <div class="text-xs sm:text-sm text-slate-400">Active Readers</div>
            <div
              class="text-lg sm:text-xl font-semibold text-woven-light-green"
            >
              {{ Math.max(wasmInfo.particleCount || 0, 1) }}
            </div>
          </div>

          <div class="bg-slate-800 rounded p-2 text-center">
            <div class="text-xs sm:text-sm text-slate-400">Connection</div>
            <div
              class="text-lg sm:text-xl font-semibold"
              :class="
                isOnlineComputed ? 'text-woven-light-green' : 'text-amber-400'
              "
            >
              {{ isOnlineComputed ? "Online" : "Offline" }}
            </div>
          </div>
        </div>

        <div
          id="wasm-canvas-container"
          class="relative h-[250px] sm:h-[300px] md:h-[350px] w-full bg-black rounded-lg overflow-hidden mb-4"
        >
          <canvas
            ref="wasmCanvas"
            class="absolute inset-0 w-full h-full"
          ></canvas>
          <div
            v-if="!wasmLoaded"
            class="absolute inset-0 flex items-center justify-center"
          >
            <div class="text-white text-opacity-80">
              Loading impression map...
            </div>
          </div>
        </div>

        <!-- Separate Vue-based legend -->
        <div
          class="flex flex-col sm:flex-row sm:items-center justify-between mb-3 bg-slate-800 rounded-lg p-3"
        >
          <!-- Legend title -->
          <div class="text-sm font-medium text-slate-300 mb-2 sm:mb-0">
            Map Legend:
          </div>

          <!-- Legend items -->
          <div class="flex flex-wrap items-center gap-3 sm:gap-4">
            <!-- Connection status -->
            <div class="flex items-center">
              <div
                :class="[
                  'w-3 h-3 rounded-full mr-2',
                  isOnlineComputed ? 'bg-green-500' : 'bg-red-500',
                ]"
              ></div>
              <span class="text-xs sm:text-sm">
                {{ isOnlineComputed ? "Connected" : "Offline" }}
              </span>
            </div>

            <!-- Node visualization explanation -->
            <div class="flex items-center">
              <div class="w-3 h-3 rounded-full bg-woven-light-green mr-2"></div>
              <span class="text-xs sm:text-sm">Active reader</span>
            </div>

            <!-- Connection visualization explanation -->
            <div class="flex items-center">
              <div class="w-5 h-1 bg-woven-light-green opacity-60 mr-2"></div>
              <span class="text-xs sm:text-sm">Connection</span>
            </div>

            <!-- Interaction explanation -->
            <div class="flex items-center">
              <div
                class="w-3 h-3 rounded-full bg-woven-light-green opacity-50 mr-2"
              ></div>
              <span class="text-xs sm:text-sm">Interaction</span>
            </div>

            <!-- Aging nodes explanation -->
            <div class="flex items-center">
              <div
                class="w-3 h-3 rounded-full bg-gradient-to-r from-woven-light-green to-transparent mr-2"
              ></div>
              <span class="text-xs sm:text-sm">Inactive viewer</span>
            </div>
          </div>
        </div>

        <p class="text-xs sm:text-sm text-slate-400 italic">
          What you're seeing above isn't a pre-rendered animation. It's a
          real-time WebAssembly module written in Rust visualizing readers as
          connected nodes. The ripples show interactions happening now, and this
          entire experience works even offline.
        </p>

        <!-- Moved Reactions Component -->
        <div class="mt-6 mb-2">
          <SectionReactions
            section-id="visualization"
            :default-expanded="true"
          />
        </div>
      </div>

      <!-- Clear separator between visualization and content -->
      <div class="border-t border-slate-700 my-10"></div>

      <div id="blog-content" class="mt-6 md:mt-8">
        <!-- Introduction -->
        <section id="intro" ref="introSection" class="mb-10 md:mb-16">
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            Today we're doing something a little different. What you're reading
            right now isn't just a blog post; it's a self-contained web
            application demonstrating the very concepts it discusses. As you
            explore this content, you're experiencing WebAssembly, offline-first
            architecture, and Progressive Web App capabilities in real-time.
          </p>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            Why are we doing this? Because at Woven, we believe these are the
            building blocks of the web of tomorrow. Resilient, high-performance
            technology and experiences that work anywhere are essential in the
            spaces our customers operate. In environments where failure isn't an
            option, forward-leaning approaches aren't just nice-to-haves.
            They're a mission-level difference maker.
          </p>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            WebAssembly (WASM) and Progressive Web Apps (PWAs) are quietly
            revolutionizing how we build and experience the web. While they
            might not be as flashy as the latest frameworks or AI tools, this
            duo is enabling a new generation of web applications that function
            seamlessly, online or off.
          </p>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            Instead of just telling you about these technologies, we've embedded
            them into this very experience.
          </p>

          <!-- Section Reactions -->
          <div class="mt-6 mb-10">
            <SectionReactions section-id="intro" />
          </div>
        </section>

        <!-- Why the Buzz -->
        <section
          id="why-the-buzz"
          ref="whyTheBuzzSection"
          class="mb-10 md:mb-16"
        >
          <h2 class="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-8">
            Why These Technologies Matter
          </h2>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            Here's why they're foundational to the future of the web:
          </p>
          <ul
            class="list-disc pl-5 sm:pl-6 mb-6 space-y-3 sm:space-y-4 text-sm sm:text-base lg:text-lg"
          >
            <li class="pl-2">
              <span class="font-medium text-woven-light-green"
                >Performance:</span
              >
              WebAssembly runs code much faster than JavaScript. The impression
              map above, which responds to your scrolling and interactions in
              real time, runs smoothly thanks to WASM's performance.
            </li>
            <li class="pl-2">
              <span class="font-medium text-woven-light-green"
                >Resilience:</span
              >
              PWAs continue working even without a network. Your reactions and
              data are stored locally and synced later. Try toggling your
              connection to test it.
            </li>
            <li class="pl-2">
              <span class="font-medium text-woven-light-green"
                >User Experience:</span
              >
              Together, they offer a seamless, app-like experience with fast
              loads, smooth interactivity, and offline functionality. All in
              your browser, no install required.
            </li>
          </ul>

          <!-- WebAssembly and PWA Key Characteristics Cards -->
          <div
            class="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 mb-6 sm:mb-8"
          >
            <div class="bg-slate-800 p-4 sm:p-6 rounded-lg">
              <h3
                class="text-lg sm:text-xl font-medium text-woven-light-green mb-3 sm:mb-4"
              >
                WebAssembly Key Characteristics:
              </h3>
              <ul
                class="list-disc pl-5 sm:pl-6 space-y-1 sm:space-y-2 text-xs sm:text-sm"
              >
                <li>Language-agnostic (compile from C, C++, Rust, and more)</li>
                <li>Near-native performance in the browser</li>
                <li>Compact binary format for faster loading</li>
                <li>Secure, sandboxed execution</li>
                <li>Supported by all major browsers as a W3C standard</li>
              </ul>
            </div>

            <!-- Condensed PWA Status Monitor Card -->
            <div class="bg-slate-800 p-4 sm:p-6 rounded-lg">
              <h3
                class="text-lg sm:text-xl font-medium text-woven-light-green mb-3 sm:mb-4"
              >
                PWA Key Characteristics:
              </h3>
              <!-- Connection Status -->
              <div class="bg-slate-700 p-3 sm:p-4 rounded mb-3 sm:mb-4">
                <div class="flex items-center">
                  <div
                    ref="connectionStatus"
                    class="w-3 sm:w-4 h-3 sm:h-4 rounded-full bg-green-500 mr-2 sm:mr-3"
                  ></div>
                  <div>
                    <div class="text-xs sm:text-sm font-medium">
                      Your Connection Status
                    </div>
                    <div
                      ref="connectionMessage"
                      class="text-xs sm:text-sm text-slate-300"
                    >
                      You're currently online.
                    </div>
                  </div>
                </div>
              </div>
              <!-- PWA Features -->
              <ul
                class="list-disc pl-5 sm:pl-6 space-y-1 sm:space-y-2 text-xs sm:text-sm"
              >
                <li>Cached assets for offline access</li>
                <li>Local data storage using IndexedDB</li>
                <li>Automatic data synchronization when online</li>
                <li>Installable as a standalone application</li>
              </ul>
              <!-- Try it note -->
              <div
                class="mt-3 sm:mt-4 p-2 bg-slate-700 rounded text-xs sm:text-sm border-l-2 border-woven-light-green"
              >
                <p class="font-medium text-woven-light-green">Try it:</p>
                <p class="text-slate-300">
                  Turn off your internet connection and continue exploring this
                  page. Everything will still work, and any interactions will
                  sync when you reconnect. You can also install this experience
                  as an app right from your browser.
                </p>
              </div>
            </div>
          </div>

          <!-- Section Reactions -->
          <div class="mt-6 mb-10">
            <SectionReactions section-id="why-the-buzz" />
          </div>
        </section>

        <!-- Real World Examples -->
        <section id="real-world" ref="realWorldSection" class="mb-10 md:mb-16">
          <h2 class="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-8">
            Real-World Applications
          </h2>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            These aren't just theoretical. They're already powering some of the
            most innovative applications on the web today:
          </p>
          <div
            class="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 mb-6 sm:mb-8"
          >
            <div class="bg-slate-800 p-4 sm:p-6 rounded-lg">
              <h3
                class="text-lg sm:text-xl text-woven-light-green mb-3 sm:mb-4"
              >
                🎨 Design Tools
              </h3>
              <p class="text-sm mb-2">
                Figma uses WebAssembly to run its powerful design engine
                (written in C++) directly in the browser, delivering
                desktop-level performance.
              </p>
            </div>
            <div class="bg-slate-800 p-4 sm:p-6 rounded-lg">
              <h3
                class="text-lg sm:text-xl text-woven-light-green mb-3 sm:mb-4"
              >
                🌍 3D Visualization
              </h3>
              <p class="text-sm mb-2">
                Google Earth Web renders complex 3D terrain using WASM, handling
                massive datasets efficiently for a buttery-smooth, interactive
                experience.
              </p>
            </div>
            <div class="bg-slate-800 p-4 sm:p-6 rounded-lg">
              <h3
                class="text-lg sm:text-xl text-woven-light-green mb-3 sm:mb-4"
              >
                🧠 AI in the Browser
              </h3>
              <p class="text-sm mb-2">
                Projects like
                <a
                  href="https://xenova.github.io/transformers.js/"
                  target="_blank"
                  class="text-woven-light-green"
                  >Transformers.js</a
                >
                let you run language models such as BERT or GPT entirely in the
                browser with WebAssembly. That means tasks like search,
                summarization, or Retrieval-Augmented Generation (RAG) can
                happen offline, with no server required.
              </p>
            </div>

            <div class="bg-slate-800 p-4 sm:p-6 rounded-lg">
              <h3
                class="text-lg sm:text-xl text-woven-light-green mb-3 sm:mb-4"
              >
                🛰️ Mission-Critical Interfaces
              </h3>
              <p class="text-sm mb-2">
                NASA's
                <a
                  href="https://github.com/nasa/openmct"
                  target="_blank"
                  class="text-woven-light-green"
                  >Open MCT</a
                >
                is a web-based platform for space mission operations.
                WebAssembly is being explored to enhance modularity and
                performance in visualizing time-critical telemetry—even in
                low-bandwidth or offline settings.
              </p>
            </div>
          </div>

          <!-- Section Reactions -->
          <div class="mt-6 mb-10">
            <SectionReactions section-id="real-world" />
          </div>
        </section>

        <!-- Resources Section -->
        <section id="resources" ref="resourcesSection" class="mb-10 md:mb-16">
          <h2 class="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-8">
            Start Building Your Own WASM + PWA Experiences
          </h2>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            Inspired? Here's your starter toolkit:
          </p>
          <ul class="space-y-3 sm:space-y-4 text-sm sm:text-base lg:text-lg">
            <li>
              <a
                href="https://vite-plugin-pwa.netlify.app/"
                class="text-woven-light-green"
              >
                <code>vite-plugin-pwa</code> </a
              >: Add PWA features to Vite apps
            </li>
            <li>
              <a
                href="https://developer.chrome.com/docs/workbox/"
                class="text-woven-light-green"
              >
                <code>Workbox</code> </a
              >: Advanced service worker strategies
            </li>
            <li>
              <a
                href="https://rustwasm.github.io/wasm-pack/"
                class="text-woven-light-green"
              >
                <code>wasm-pack</code> </a
              >: Compile Rust to WASM with ease
            </li>
            <li>
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API"
                class="text-woven-light-green"
              >
                <code>IndexedDB</code> </a
              >: Client-side storage for offline-first apps
            </li>
          </ul>

          <!-- Section Reactions -->
          <div class="mt-6 mb-10">
            <SectionReactions section-id="resources" />
          </div>
        </section>

        <!-- Conclusion -->
        <section id="conclusion" ref="conclusionSection" class="mb-10 md:mb-16">
          <h2 class="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-8">
            Building the Future Web Together
          </h2>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            The future looks even more promising as these technologies continue
            to evolve. WASI (WebAssembly System Interface) is extending WASM
            beyond the browser, while PWA capabilities are bringing
            desktop-class features to web apps. Together, they're blurring the
            lines between native applications and web content, creating a more
            unified and accessible digital ecosystem.
          </p>
          <p class="text-sm sm:text-base lg:text-lg mb-4 md:mb-6">
            At Woven, we're not just watching this shift, we're building for it.
            By embedding these resilient, high-performance patterns into our
            tooling and platforms, we're helping teams deliver experiences that
            thrive in the real world: offline, on the edge, and everywhere in
            between.
          </p>
          <!-- Section Reactions -->
          <div class="mt-6 mb-10">
            <SectionReactions section-id="conclusion" />
          </div>
        </section>

        <!-- Social Sharing Section -->
        <div class="border-t border-slate-700 pt-6 mt-10">
          <h3 class="text-xl text-woven-light-green mb-4">Share This Post</h3>
          <div class="flex flex-wrap gap-4">
            <!-- X (formerly Twitter) -->
            <button
              class="w-10 h-10 flex items-center justify-center bg-slate-800 hover:bg-slate-700 rounded-full transition-colors duration-200"
              title="Share on X"
              @click="shareToTwitter"
            >
              <svg
                class="w-5 h-5 text-white"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <!-- X logo SVG path -->
                <path
                  d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                ></path>
              </svg>
            </button>

            <!-- LinkedIn -->
            <button
              class="w-10 h-10 flex items-center justify-center bg-slate-800 hover:bg-slate-700 rounded-full transition-colors duration-200"
              title="Share on LinkedIn"
              @click="shareToLinkedIn"
            >
              <svg
                class="w-5 h-5 text-[#0A66C2]"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.454C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.225 0z"
                ></path>
              </svg>
            </button>

            <!-- Facebook -->
            <button
              class="w-10 h-10 flex items-center justify-center bg-slate-800 hover:bg-slate-700 rounded-full transition-colors duration-200"
              title="Share on Facebook"
              @click="shareToFacebook"
            >
              <svg
                class="w-5 h-5 text-[#4267B2]"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>

            <!-- WhatsApp -->
            <button
              class="w-10 h-10 flex items-center justify-center bg-slate-800 hover:bg-slate-700 rounded-full transition-colors duration-200"
              title="Share on WhatsApp"
              @click="shareToWhatsapp"
            >
              <svg
                class="w-5 h-5 text-[#25D366]"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015-.2 0-.523.074-.797.359-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885M20.52 3.449C18.24 1.245 15.24 0 12.045 0 5.463 0 .104 5.334.101 11.893c0 2.096.549 4.14 1.595 5.945L0 24l6.335-1.652c1.746.943 3.71 1.444 5.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411"
                ></path>
              </svg>
            </button>

            <!-- Email -->
            <button
              class="w-10 h-10 flex items-center justify-center bg-slate-800 hover:bg-slate-700 rounded-full transition-colors duration-200"
              title="Share via Email"
              @click="shareViaEmail"
            >
              <svg
                class="w-5 h-5 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path
                  d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                ></path>
                <path
                  d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                ></path>
              </svg>
            </button>

            <!-- Copy Link Button -->
            <button
              class="w-10 h-10 flex items-center justify-center bg-slate-800 hover:bg-slate-700 rounded-full transition-colors duration-200 relative"
              title="Copy link to clipboard"
              @click="copyWasmBlogLink"
            >
              <svg
                class="w-5 h-5 text-slate-300"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span
                v-if="wasmLinkCopied"
                class="absolute -top-8 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-slate-700 text-white text-xs rounded"
              >
                Copied!
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import dayjs from "dayjs";
import SectionReactions from "@/components/SectionReactions.vue";
import * as ReactionSystem from "@/wasm/reactionSystem";
import * as OfflineSync from "@/wasm/offlineSync";

export default {
  components: {
    SectionReactions,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Format the date like the BlogPost component does
    const formattedDate = computed(() => {
      return dayjs(props.post.date).format("MMMM D, YYYY");
    });

    // Calculate reading time
    const readingTime = computed(() => {
      // Check if post content exists
      if (!props.post?.content?.rendered) return 1;

      // Calculate reading time based on content length
      // Average reading speed: 225 words per minute
      const wordCount = props.post.content.rendered
        .replace(/<[^>]*>/g, "") // Remove HTML tags
        .split(/\s+/)
        .filter((word) => word.length > 0).length;

      const minutes = Math.ceil(wordCount / 225);
      return minutes > 0 ? minutes : 1; // Minimum 1 minute
    });

    // Create refs to hold interval IDs for cleanup
    const heartbeatIntervalId = ref(null);
    const reactionIntervalId = ref(null);

    // Get author info like the BlogPost component does
    const authorInfo = computed(() => {
      const { attributable_author_name, attributable_author_job_title } =
        props.post.acf || {};

      if (attributable_author_name) {
        if (attributable_author_job_title) {
          return `${attributable_author_name}, ${attributable_author_job_title}`;
        }
        return `${attributable_author_name}`;
      }
      return null;
    });

    // Current URL for sharing
    const currentUrl = computed(() => {
      return window.location.href;
    });

    // For copy link functionality
    const wasmLinkCopied = ref(false);

    // Copy WASM blog link to clipboard
    const copyWasmBlogLink = async () => {
      try {
        await navigator.clipboard.writeText(currentUrl.value);
        wasmLinkCopied.value = true;
        setTimeout(() => {
          wasmLinkCopied.value = false;
        }, 2000);
      } catch (error) {
        console.error("Failed to copy link:", error);
      }
    };

    // Social sharing methods
    const shareToTwitter = () => {
      const text = encodeURIComponent(
        "Building Blocks for the Future Web - Woven"
      );
      const url = encodeURIComponent(currentUrl.value);
      const hashtags = "WebAssembly,PWA,WebDev";
      window.open(
        `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hashtags}`,
        "_blank"
      );
    };

    const shareToFacebook = () => {
      const url = encodeURIComponent(currentUrl.value);
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        "_blank"
      );
    };

    const shareToLinkedIn = () => {
      const url = encodeURIComponent(currentUrl.value);
      const title = encodeURIComponent(
        "Building Blocks for the Future Web - Woven"
      );
      const summary = encodeURIComponent(
        "This article showcases WebAssembly and PWA technologies in a practical example"
      );
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${summary}`,
        "_blank"
      );
    };

    const shareToWhatsapp = () => {
      const url = encodeURIComponent(currentUrl.value);
      const text = encodeURIComponent(
        "Building Blocks for the Future Web - Woven"
      );
      window.open(`https://wa.me/?text=${text}%20${url}`, "_blank");
    };

    const shareViaEmail = () => {
      const subject = encodeURIComponent(
        "Building Blocks for the Future Web - Woven"
      );
      const body = encodeURIComponent(
        `Check out this article about WebAssembly and PWAs: ${currentUrl.value}`
      );
      window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
    };

    // Computed property for online status to avoid direct navigator access in template
    const isOnlineComputed = computed(() => {
      // Use the reactive ref instead of checking navigator.onLine directly
      // This ensures the computed property updates when isOnline changes
      return isOnline.value;
    });

    // Check if WebAssembly is supported in this browser
    const isWasmSupported = computed(() => {
      return (
        typeof WebAssembly === "object" &&
        typeof WebAssembly.instantiate === "function" &&
        wasmLoaded.value
      );
    });

    // Use real reactions from ReactionSystem for the reaction demo section
    const reactions = computed(() => {
      // Get reaction types from ReactionSystem
      const reactionTypes = Object.values(ReactionSystem.REACTION_TYPES);

      // Map to the format expected by the template
      return reactionTypes.map((type) => {
        // Get count from the reaction data for the "reaction-demo" section
        const count = reactionData.value.filter(
          (r) => r.sectionId === "reaction-demo" && r.type === type.id
        ).length;

        return {
          emoji: type.emoji,
          count: count,
          id: type.id,
        };
      });
    });

    // Track which reactions the current user has selected
    const userReactions = computed(() => {
      if (!reactions.value) return [];

      // Instead of using a non-existent hasUserReacted function, check the reaction data
      return reactions.value.map((reaction) => {
        // Look through reactionData to see if the current user has this reaction type
        // on the "reaction-demo" section
        const userReaction = reactionData.value.find(
          (r) =>
            r.sectionId === "reaction-demo" &&
            r.type === reaction.id &&
            r.sessionId === ReactionSystem.getSessionId()
        );

        return !!userReaction; // Return true if user has this reaction
      });
    });

    // Track if we have any pending reactions to sync
    const reactionPending = computed(() => {
      // This will now be determined using the new getPendingReactions method
      return false; // Default to false, SectionReactions component will handle this internally
    });

    // Handle reaction clicks using the real ReactionSystem
    const handleReaction = async (index) => {
      try {
        const reactionType = reactions.value[index]?.id;
        if (!reactionType) return;

        const sectionId = "reaction-demo";

        if (userReactions.value[index]) {
          // User already reacted, remove the reaction
          await ReactionSystem.removeReaction(sectionId, reactionType);
        } else {
          // Add new reaction
          await ReactionSystem.addReaction(sectionId, reactionType);
        }

        // Refresh reactions data
        await getAllReactions();

        // If we're online, sync will happen automatically from the removeReaction/addReaction methods
      } catch (error) {
        console.error("Error handling reaction:", error);
      }
    };

    // Canvas references for visualization
    const wasmCanvas = ref(null);

    // Section references for scroll detection
    const introSection = ref(null);
    const whyTheBuzzSection = ref(null);
    const realWorldSection = ref(null);
    const interactiveSection = ref(null);
    const analyticsSection = ref(null);
    const conclusionSection = ref(null);
    const resourcesSection = ref(null);

    // UI elements to be dynamically updated
    // Note: offlineIndicator has been removed as we now have connection status in the WebAssembly section
    const connectionStatus = ref(null);
    const connectionMessage = ref(null);
    const wasmReveal = ref(null);
    const analyticsDashboard = ref(null);

    // State variables
    const wasmLoaded = ref(false);
    const wasmModule = ref(null);
    const hasRevealed = ref(false);
    const visibleSections = ref(new Set());
    const intersectionObserver = ref(null);
    const sessionStartTime = ref(Date.now());
    // Store animation frame ID for cleanup
    const animationFrameId = ref(null);

    // Safe online status handling
    const isOnline = ref(false);
    // Initialize online status safely
    if (typeof window !== "undefined" && typeof navigator !== "undefined") {
      isOnline.value = navigator.onLine;
    }

    // Empty analytics data object (kept to prevent template errors, but won't be updated)
    const analyticsData = ref({
      activeReaders: 0,
      totalViews: 0,
      averageReadTime: "0:00",
      offlineReaders: 0,
      unsyncedEvents: 0,
      events: [],
    });

    // Analytics dashboard elements (needed for template references)
    const activeReaders = ref(null);
    const totalViews = ref(null);
    const averageReadTime = ref(null);
    const offlineReaders = ref(null);

    // Sync status
    const isSyncing = ref(false);
    const lastSyncTime = ref(null);
    const syncStatus = ref("idle"); // idle, syncing, success, error

    // Module status information
    const wasmInfo = ref({
      supported: false,
      active: false,
      particleCount: 0,
      implementation: "Initializing...",
    });

    // PWA installation status
    const isPwaInstalled = ref(false);

    // UI state for collapsible sections
    const showEventLog = ref(false);

    // Reactions and section views data
    const reactionData = ref([]);

    // Format section name for display
    const formatSectionName = (sectionId) => {
      const sectionMap = {
        intro: "Introduction",
        "why-the-buzz": "Why These Technologies Matter",
        "real-world": "Real-World Applications",
        resources: "Resources",
        visualization: "Visitor Impression Map",
        conclusion: "Building the Future Web Together",
      };

      return sectionMap[sectionId] || sectionId;
    };

    // Get all reactions from all sections
    const getAllReactions = async () => {
      try {
        // Initialize reaction system if needed
        await ReactionSystem.initReactions();

        // Get the sections we want to check for reactions
        const sections = [
          "intro",
          "why-the-buzz",
          "real-world",
          "resources",
          "visualization",
          "conclusion",
        ];

        const allReactions = [];

        // Get reactions for each section
        for (const sectionId of sections) {
          const sectionReactions =
            ReactionSystem.getReactionsForSection(sectionId);

          // Map the reactions to a display-friendly format
          const displayReactions = sectionReactions.map((reaction) => {
            const reactionType =
              ReactionSystem.REACTION_TYPES[reaction.type.toUpperCase()] ||
              Object.values(ReactionSystem.REACTION_TYPES).find(
                (r) => r.id === reaction.type
              );

            return {
              id: reaction.id,
              sectionId,
              type: reaction.type,
              emoji: reactionType?.emoji || "👍",
              label: reactionType?.label || reaction.type,
              timestamp: reaction.timestamp,
              sessionId: reaction.sessionId,
            };
          });

          allReactions.push(...displayReactions);
        }

        // Sort by timestamp, newest first
        allReactions.sort((a, b) => b.timestamp - a.timestamp);

        // Store all reactions in the data
        reactionData.value = allReactions;

        return allReactions;
      } catch (error) {
        console.error("Failed to get reactions:", error);
        return [];
      }
    };

    // Get reactions for a specific section
    const getSectionReactions = (sectionId) => {
      return reactionData.value.filter(
        (reaction) => reaction.sectionId === sectionId
      );
    };

    // Get reaction counts for a specific section
    const getSectionReactionCounts = (sectionId) => {
      const sectionReactions = getSectionReactions(sectionId);
      const counts = {};

      // Count each reaction type
      sectionReactions.forEach((reaction) => {
        if (!counts[reaction.type]) {
          counts[reaction.type] = {
            type: reaction.type,
            emoji: reaction.emoji,
            count: 0,
          };
        }
        counts[reaction.type].count++;
      });

      return Object.values(counts);
    };

    // Get total reaction counts by emoji type
    const getTotalReactionCounts = () => {
      const totalCounts = {};

      // Count each reaction type across all sections
      reactionData.value.forEach((reaction) => {
        if (!totalCounts[reaction.type]) {
          totalCounts[reaction.type] = {
            type: reaction.type,
            emoji: reaction.emoji,
            label: reaction.label,
            count: 0,
          };
        }
        totalCounts[reaction.type].count++;
      });

      // Convert to array and sort by count (highest first)
      return Object.values(totalCounts).sort((a, b) => b.count - a.count);
    };

    // Get total reaction count across all sections
    const getTotalReactionCount = () => {
      if (!reactionData.value) return 0;
      return reactionData.value.length;
    };

    // Format session time as minutes:seconds
    const formatSessionTime = () => {
      const seconds = Math.floor((Date.now() - sessionStartTime.value) / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    };

    // Set up global event listeners for connection changes
    if (typeof window !== "undefined") {
      window.addEventListener("online", () => {
        console.log("Connection event: online");
        handleConnectionChange();
      });
      window.addEventListener("offline", () => {
        console.log("Connection event: offline");
        handleConnectionChange();
      });
    }

    // Initialize impression map module
    onMounted(async () => {
      try {
        // First, set up the canvas with a context
        if (wasmCanvas.value) {
          const canvas = wasmCanvas.value;
          const ctx = canvas.getContext("2d");

          // Set canvas dimensions to match container
          const resizeCanvas = () => {
            const container = canvas.parentElement;
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;
          };

          // Handle window resize
          window.addEventListener("resize", resizeCanvas);
          resizeCanvas();

          try {
            // Add our loader script to the page first
            // This will load and initialize the WebAssembly module
            const loaderScript = document.createElement("script");
            loaderScript.src = "/wasm/impression-map/impression-map-loader.js";
            loaderScript.type = "module";
            document.head.appendChild(loaderScript);

            // Wait a moment to ensure the script has a chance to load
            await new Promise((resolve) => setTimeout(resolve, 500));

            // Load the impression map module
            const impressionMap = await import("@/wasm/impressionMap.js");
            wasmModule.value = impressionMap;

            // Initialize the impression map system
            await wasmModule.value.initImpressionMap(
              canvas.width,
              canvas.height
            );
            wasmLoaded.value = true;

            // Get module information
            wasmInfo.value = wasmModule.value.getImpressionMapInfo();

            // Animation loop
            let lastFrame = performance.now();
            let infoUpdateTimer = 0;
            const animate = (now) => {
              const dt = now - lastFrame;
              lastFrame = now;

              // Update and render impression map
              wasmModule.value.updateImpressionMap(dt / 1000);
              wasmModule.value.renderImpressionMap(ctx);

              // Periodically update the wasmInfo object to refresh connected user count
              infoUpdateTimer += dt;
              if (infoUpdateTimer > 2000) {
                // Update every 2 seconds
                const updatedInfo = wasmModule.value.getImpressionMapInfo();
                console.log("Updated wasmInfo:", updatedInfo);

                // Fix: Manually assign particleCount from viewerCount
                if (
                  updatedInfo &&
                  typeof updatedInfo.viewerCount !== "undefined"
                ) {
                  updatedInfo.particleCount = updatedInfo.viewerCount;
                }

                wasmInfo.value = updatedInfo;
                infoUpdateTimer = 0;
              }

              // Continue animation loop - store the ID for later cancellation
              animationFrameId.value = requestAnimationFrame(animate);
            };

            // Start animation
            animationFrameId.value = requestAnimationFrame(animate);
          } catch (error) {
            console.error("Error initializing WebAssembly module:", error);
            wasmLoaded.value = true; // Show canvas anyway, even though it won't work
          }
        }

        // Initialize reaction system and load reaction data
        await ReactionSystem.initReactions();
        await getAllReactions();

        // Import offlineSync to use the heartbeat function
        const sendRegularHeartbeat = async () => {
          if (typeof OfflineSync.sendHeartbeat === "function") {
            await OfflineSync.sendHeartbeat({
              page: window.location.pathname,
              referrer: document.referrer,
              viewportWidth: window.innerWidth,
              viewportHeight: window.innerHeight,
              userAgent: navigator.userAgent,
            });
          }
        };

        // Send initial heartbeat
        sendRegularHeartbeat();

        // Set up periodic updates and heartbeats
        heartbeatIntervalId.value = setInterval(sendRegularHeartbeat, 30000); // Send heartbeat every 30 seconds

        // Also update reactions periodically
        reactionIntervalId.value = setInterval(async () => {
          if (isOnlineComputed.value) {
            try {
              // Force a reload of reaction data from storage
              await ReactionSystem.loadFromStorage();

              // Try to sync any pending reactions
              await ReactionSystem.syncReactions();

              // Get fresh reaction data
              await getAllReactions();

              console.log("Periodic reaction refresh completed");
            } catch (error) {
              console.warn("Error during periodic reaction refresh:", error);
            }
          }
        }, 30000);

        // Listen for sync events from the service worker
        window.addEventListener("sync-completed", () => {
          console.log("Sync completed event received");
          syncStatus.value = "success";
          lastSyncTime.value = new Date();
        });

        // Initialize connection status
        if (connectionStatus.value && connectionMessage.value) {
          updateConnectionStatus();
        } else {
          // Try again in a moment if refs aren't set yet
          setTimeout(updateConnectionStatus, 500);
        }

        // Set up scroll tracking with Intersection Observer
        const observerOptions = {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        };

        intersectionObserver.value = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              visibleSections.value.add(entry.target.id);

              // Trigger specific actions for certain sections
              if (
                entry.target.id === "conclusion" &&
                wasmReveal.value &&
                !hasRevealed.value
              ) {
                setTimeout(() => {
                  wasmReveal.value.style.opacity = "1";
                  hasRevealed.value = true;
                }, 500);
              }

              // If interactive section becomes visible, create a ripple effect for the user
              if (entry.target.id === "interactive-demo" && wasmModule.value) {
                // Create a ripple effect at the user's node position
                if (wasmModule.value.createRippleEffect) {
                  setTimeout(() => {
                    // We don't need to pass coordinates as the module will use the user's node position
                    wasmModule.value.createRippleEffect();
                  }, 500);
                }
              }
            } else {
              visibleSections.value.delete(entry.target.id);
            }
          });
        }, observerOptions);

        // Observe all sections
        [
          introSection.value,
          whyTheBuzzSection.value,
          realWorldSection.value,
          resourcesSection.value,
          interactiveSection.value,
          analyticsSection.value,
          conclusionSection.value,
        ].forEach((section) => {
          if (section) {
            intersectionObserver.value.observe(section);
          }
        });

        // Check if running as installed PWA
        if (window.matchMedia("(display-mode: standalone)").matches) {
          isPwaInstalled.value = true;
        }

        // Listen for display mode changes (if user installs during session)
        window
          .matchMedia("(display-mode: standalone)")
          .addEventListener("change", (evt) => {
            isPwaInstalled.value = evt.matches;
          });
      } catch (error) {
        console.error("Failed to initialize:", error);
        wasmLoaded.value = true; // Show canvas anyway
      }
    });

    // Handle connection changes
    const handleConnectionChange = () => {
      // Safe check for navigator object
      if (typeof navigator !== "undefined") {
        console.log(
          "Connection change detected:",
          navigator.onLine ? "Online" : "Offline"
        );
        isOnline.value = navigator.onLine;
      }
      updateConnectionStatus();
    };

    // Update connection status
    const updateConnectionStatus = () => {
      console.log(
        "Updating connection status UI, online:",
        isOnlineComputed.value
      );
      console.log(
        "Connection elements:",
        !!connectionStatus.value,
        !!connectionMessage.value
      );

      if (!connectionStatus.value || !connectionMessage.value) return;

      if (isOnlineComputed.value) {
        connectionStatus.value.classList.remove("bg-red-500");
        connectionStatus.value.classList.add("bg-green-500");
        connectionMessage.value.textContent = "You're currently online.";
      } else {
        connectionStatus.value.classList.remove("bg-green-500");
        connectionStatus.value.classList.add("bg-red-500");
        connectionMessage.value.textContent =
          "You're currently offline, but this blog still works!";
      }
    };

    // Simplified sync function for reactions only (no analytics)
    const attemptSync = async () => {
      if (isSyncing.value || !isOnlineComputed.value) return;

      try {
        isSyncing.value = true;
        syncStatus.value = "syncing";

        // Call the syncReactions method from ReactionSystem
        await ReactionSystem.syncReactions();

        // Refresh reaction data after sync
        await getAllReactions();

        syncStatus.value = "success";
        lastSyncTime.value = new Date();
      } catch (error) {
        console.error("Sync failed:", error);
        syncStatus.value = "error";
      } finally {
        isSyncing.value = false;
      }
    };

    // Clean up event listeners when component is unmounted
    onUnmounted(() => {
      console.log("wasm-blog component unmounting - cleaning up resources");

      // Cancel animation frame if it exists
      if (animationFrameId.value) {
        console.log("Cancelling animation frame");
        cancelAnimationFrame(animationFrameId.value);
        animationFrameId.value = null;
      }

      // Clear all interval timers
      if (heartbeatIntervalId.value) {
        console.log("Clearing heartbeat interval");
        clearInterval(heartbeatIntervalId.value);
        heartbeatIntervalId.value = null;
      }

      if (reactionIntervalId.value) {
        console.log("Clearing reaction refresh interval");
        clearInterval(reactionIntervalId.value);
        reactionIntervalId.value = null;
      }

      // Clean up reaction system resources
      console.log("Cleaning up reaction system resources");
      if (ReactionSystem.cleanupReactionSystem) {
        ReactionSystem.cleanupReactionSystem();
      }

      // Clean up offline sync system resources
      console.log("Cleaning up offline sync resources");
      if (OfflineSync.cleanupOfflineSync) {
        OfflineSync.cleanupOfflineSync();
      }

      // Remove event listeners
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
      window.removeEventListener("sync-completed", () => {});

      if (intersectionObserver.value) {
        intersectionObserver.value.disconnect();
      }

      // Clean up impression map
      if (wasmModule.value && wasmModule.value.cleanupImpressionMap) {
        console.log("Cleaning up impression map WebAssembly resources");
        wasmModule.value.cleanupImpressionMap();
        wasmModule.value = null;
      }

      // Additional cleanup for any global WebAssembly resources
      // This ensures no lingering references to WebAssembly modules
      try {
        console.log("Performing additional WebAssembly cleanup");

        // Clear any global references to WebAssembly modules or constructors
        if (window.ImpressionMap) {
          console.log("Clearing global ImpressionMap reference");
          if (typeof window.ImpressionMap.cleanup === "function") {
            try {
              window.ImpressionMap.cleanup();
            } catch (e) {
              console.warn("Error during global ImpressionMap cleanup:", e);
            }
          }
          window.ImpressionMap = null;
        }

        if (window.ImpressionMapModule) {
          console.log("Clearing global ImpressionMapModule reference");
          window.ImpressionMapModule = null;
        }

        // Force garbage collection of WebAssembly memory if possible
        if (window.gc) {
          console.log("Forcing garbage collection");
          window.gc();
        }
      } catch (error) {
        console.error("Error during additional WebAssembly cleanup:", error);
      }
    });

    // Return all properties and methods needed in the template
    return {
      formattedDate,
      authorInfo,
      readingTime,
      analyticsData,
      isPwaInstalled,
      wasmInfo,
      isOnlineComputed,
      isWasmSupported,
      syncStatus,
      lastSyncTime,
      isSyncing,
      wasmLoaded,
      showEventLog,
      wasmCanvas,
      introSection,
      whyTheBuzzSection,
      realWorldSection,
      interactiveSection,
      analyticsSection,
      conclusionSection,
      resourcesSection,
      wasmReveal,
      connectionStatus,
      connectionMessage,
      analyticsDashboard,
      activeReaders,
      totalViews,
      averageReadTime,
      offlineReaders,
      reactionData,
      getTotalReactionCount,
      formatSessionTime,
      handleReaction,
      reactions,
      userReactions,
      reactionPending,
      getSectionReactions,
      getSectionReactionCounts,
      getTotalReactionCounts,
      formatSectionName,
      attemptSync,
      currentUrl,
      wasmLinkCopied,
      copyWasmBlogLink,
      shareToTwitter,
      shareToFacebook,
      shareToLinkedIn,
      shareToWhatsapp,
      shareViaEmail,
    };
  },
};
</script>

<style lang="scss">
/* Custom styles for the WASM blog */
#wasm-canvas-container {
  box-shadow: 0 0 30px rgba(16, 185, 129, 0.2);
}

.transition-opacity {
  transition: opacity 1.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Ensure the wasm-reveal is initially hidden */
#wasm-reveal {
  opacity: 0;
}

/* Style for section reactions to make them more distinct */
.section-reactions {
  position: relative;
  padding: 12px;
  border-radius: 8px;
  background-color: rgba(30, 41, 59, 0.5);
  border-left: 3px solid rgba(16, 185, 129, 0.5);
}

/* Media query for small screens to reduce shadow effect */
@media (max-width: 640px) {
  #wasm-canvas-container {
    box-shadow: 0 0 15px rgba(16, 185, 129, 0.15);
  }
}
</style>
