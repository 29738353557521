<template>
  <!-- Loading overlay spinner - appears on top of content instead of replacing it -->
  <div
    v-if="loading && !initialLoad"
    class="fixed inset-0 bg-slate-900/70 flex justify-center items-center z-20"
  >
    <div
      class="bg-slate-800 rounded-lg p-8 shadow-lg flex flex-col items-center"
    >
      <div
        class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-woven-green mb-4"
      ></div>
      <p class="text-slate-300 text-lg">Loading posts...</p>
    </div>
  </div>

  <!-- Initial loading state - only shown on first page load -->
  <div v-if="initialLoad" class="flex justify-center items-center h-screen">
    <div
      class="bg-slate-800 rounded-lg p-8 shadow-lg flex flex-col items-center"
    >
      <div
        class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-woven-green mb-4"
      ></div>
      <p class="text-slate-300 text-lg">Loading posts...</p>
    </div>
  </div>

  <div
    v-else-if="error"
    class="flex justify-center items-center h-screen text-red-500"
  >
    Error: {{ error.message }}
  </div>

  <div v-else class="px-6 lg:px-10 py-20">
    <section class="max-w-7xl mx-auto">
      <h2 class="mt-6 text-4xl lg:text-5xl font-bold text-white">Insights</h2>
      <p class="mt-4 text-base lg:text-lg text-slate-300">
        Discover the latest insights, tips, and trends in our industry. Dive
        into our world of innovative ideas and learn more about what drives us.
      </p>
      <CategoryFilter
        v-model="selectedCategory"
        :categories="categories"
        :loading="loading"
        @category-selected="handleCategorySelected"
      />
      <div
        class="mt-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-8"
      >
        <template v-if="posts.length === 0">
          <div class="col-span-full text-center text-gray-500">
            No posts yet, check back soon!
          </div>
        </template>
        <BlogPostCard v-for="post in posts" :key="post.id" :post="post" />
      </div>
      <div
        v-if="loadingMore"
        class="flex justify-center items-center mt-6 py-4"
      >
        <div
          class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-woven-green"
        ></div>
      </div>
      <div v-if="allPostsLoaded" class="text-center text-slate-400 mt-8 py-2">
        No more posts to load
      </div>

      <!-- Manual load more button as fallback -->
      <div
        v-if="!loadingMore && !allPostsLoaded && posts.length > 0"
        class="flex justify-center mt-8"
      >
        <button
          class="bg-woven-green text-black px-6 py-3 rounded-md hover:bg-opacity-90"
          @click="loadMorePosts"
        >
          Load More Posts
        </button>
      </div>

      <!-- Spacer to ensure scrolling works properly -->
      <div class="h-10 mt-8"></div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, defineComponent } from "vue";
import BlogPostCard from "@/components/BlogPostCard.vue";
import CategoryFilter from "@/components/CategoryFilter.vue";
import {
  fetchPosts,
  fetchCategories,
} from "@/shared/services/enhancedWpService";

export default defineComponent({
  components: {
    BlogPostCard,
    CategoryFilter,
  },
  setup() {
    const posts = ref([]);
    const categories = ref([]);
    const loading = ref(true);
    const loadingMore = ref(false);
    const error = ref(null);
    const page = ref(1);
    const perPage = ref(12);
    const totalPages = ref(null);
    const selectedCategory = ref(null);
    const allPostsLoaded = ref(false);
    const initialLoad = ref(true);

    const loadPosts = async (page, category = null) => {
      try {
        // Don't try to load more if we already know we're at the end
        if (allPostsLoaded.value && page > 1) {
          loadingMore.value = false;
          return;
        }

        const { data, headers } = await fetchPosts(
          perPage.value,
          page,
          category
        );

        // Check total pages from response headers
        if (totalPages.value === null) {
          totalPages.value = parseInt(headers["x-wp-totalpages"], 10);
        }

        if (page === 1) {
          posts.value = data;
        } else {
          // Only append new posts if we got any
          if (data.length > 0) {
            posts.value = [...posts.value, ...data];
          }
        }

        // Check if we've loaded all available posts
        if (data.length === 0 || page >= totalPages.value) {
          allPostsLoaded.value = true;
        }
      } catch (err) {
        // If this is the first page load and it fails, show the error to the user
        if (page === 1) {
          error.value = err;
        } else {
          // For subsequent page loads, just mark as complete
          console.warn("End of content or error:", err.message);
          allPostsLoaded.value = true;
        }
      } finally {
        loading.value = false;
        loadingMore.value = false;
        initialLoad.value = false;
      }
    };

    const loadCategories = async () => {
      try {
        categories.value = await fetchCategories();
      } catch (err) {
        error.value = err;
      }
    };

    const handleCategorySelected = (category) => {
      selectedCategory.value = category;
      page.value = 1;
      totalPages.value = null;
      allPostsLoaded.value = false;
      loading.value = true;
      loadPosts(page.value, category ? category.id : null);
    };

    // Simple method to load more posts
    const loadMorePosts = () => {
      if (!loadingMore.value && !allPostsLoaded.value) {
        loadingMore.value = true;
        page.value++;
        console.log("Loading more posts, page:", page.value);
        loadPosts(
          page.value,
          selectedCategory.value ? selectedCategory.value.id : null
        );
      }
    };

    // Set up scroll handling with a simpler approach
    const onScroll = () => {
      if (loadingMore.value || allPostsLoaded.value) return;

      const scrollHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      );
      const scrollTop = window.scrollY;
      const clientHeight = window.innerHeight;

      // Load more when user scrolls to 200px from bottom
      if (scrollTop + clientHeight >= scrollHeight - 200) {
        loadMorePosts();
      }
    };

    onMounted(() => {
      loadPosts(page.value);
      loadCategories();

      // Add scroll event listener
      window.addEventListener("scroll", onScroll);
    });

    onUnmounted(() => {
      // Remove scroll event listener
      window.removeEventListener("scroll", onScroll);
    });

    return {
      posts,
      categories,
      loading,
      initialLoad,
      loadingMore,
      error,
      handleCategorySelected,
      allPostsLoaded,
      loadMorePosts,
      selectedCategory,
    };
  },
});
</script>

<style lang="scss"></style>
